/**
 * Module dependencies.
 */

import type { Menu as MenuItemProps } from 'src/types/menu';
import { RouterLink } from 'src/components/core/links/router-link';
import { getRouteByPathname } from 'src/core/utils/routes';
import { ifProp } from 'styled-tools';
import { media } from 'src/styles/utils/media';
import { textStyles } from 'src/components/core/text';
import { useEffect, useState } from 'react';
import { useRouteResolver } from 'src/hooks/use-route-resolver';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * `Line` styled component.
 */

const Line = styled.span`
  align-self: center;
  background-color: currentColor;
  content: '';
  grid-area: label;
  height: var(--line-height);
  transform: scaleX(0) translateY(-50%);
  transform-origin: 0 50%;
  transition: var(--transition-default);
  transition-property: transform, opacity;
  width: var(--line-width);
`;

/**
 * `Label` styled component.
 */

const Label = styled.span`
  display: block;
  grid-area: label;
  transition: var(--transition-default);
  transition-property: transform, opacity;
`;

/**
 * `SubmenuList` styled component.
 */

const SubmenuList = styled.ul`
  grid-area: submenu;
  opacity: 0;
  overflow: hidden;
  padding-left: var(--line-width);
  transition: opacity var(--transition-fast);
  width: min-content;
`;

/**
 * `Link` styled component.
 */

const Link = styled.a.attrs(({ href }) => ({
  as: (href && RouterLink) || 'div'
}))<{
  active?: boolean;
  disabled?: boolean;
  hasSubmenu?: boolean;
  isSmall?: boolean;
}>`
  --line-height: 3px;
  --line-width: 20px;

  ${media.min.ms`
    --line-height: 4px;
    --line-width: 32px;
  `}

  ${ifProp('isSmall', textStyles.submenu, textStyles.menu)}

  cursor: pointer;
  display: grid;
  grid-template-areas: 'label .' '. submenu';
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 0fr;
  opacity: ${ifProp('active', 1, 0.5)};
  transition: var(--transition-default);
  transition-delay: 0s;
  transition-property: grid-template-rows, opacity;
  white-space: nowrap;
  width: max-content;

  &[data-active='true'],
  :hover,
  :focus,
  :focus-within {
    opacity: 1;
    outline: none;

    ${ifProp(
    'hasSubmenu',
    `
      grid-template-rows: min-content 1fr;
      transition-delay: 300ms;

      ${Label} {
        transform: translateX(calc(var(--line-width) * 1.5));
      }

      ${Line} {
        opacity: 1;
        transform: scaleX(1) translateY(-50%);
      }

      ${SubmenuList} {
        opacity: 1;
      }
    `
  )}
  }

  ${ifProp(
    'disabled',
    `
    cursor: default;
    opacity: 0.25;
    pointer-events: none;
  `
  )}
`;

/**
 * Export `MenuItem` component.
 */

export function MenuItem(props: MenuItemProps & { active: boolean }) {
  const { t } = useTranslation();
  const { active, label, routeInterpolations, routeName, submenu } = props;
  const router = useRouter();
  const currentRoute = getRouteByPathname(router?.pathname);
  const [isSubmenuOpen, setSubmenuOpen] = useState<boolean>(active);
  const resolveRoute = useRouteResolver();
  const url = routeName
    ? resolveRoute(routeName, routeInterpolations)
    : undefined;

  useEffect(() => {
    const handleRouteChange = () => {
      if (isSubmenuOpen) {
        setSubmenuOpen(false);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [isSubmenuOpen, router.events]);

  return (
    <Link
      active={active}
      data-active={active}
      disabled={!routeName && isEmpty(submenu)}
      hasSubmenu={!isEmpty(submenu)}
      href={url}
    >
      {isEmpty(submenu) ? (
        t(label)
      ) : (
        <>
          <Label>{t(label)}</Label>

          <Line />

          <SubmenuList>
            {submenu?.map(
              ({ label, routeInterpolations, routeName }, index) => {
                const isActive = routeName === currentRoute;
                const url =
                  isActive || !routeName
                    ? undefined
                    : resolveRoute(routeName, routeInterpolations);

                return (
                  <li key={index}>
                    <Link
                      active={isActive}
                      href={url}
                      isSmall
                    >
                      {t(label)}
                    </Link>
                  </li>
                );
              }
            )}
          </SubmenuList>
        </>
      )}
    </Link>
  );
}
