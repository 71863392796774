/**
 * Module dependencies.
 */

import { clipFrom, fadeFrom } from './keyframes';
import { createGlobalStyle, css } from 'styled-components';
import { rootVariables } from './variables';
import { textStyles } from 'src/components/core/text';
import styledNormalize from 'styled-normalize';

/**
 * Animation delays.
 *
 * Generates delays from 100 to 1000.
 */

const animationDelays = Array.from(Array(10).keys())
  .reduce((previous, key) => {
    const value = key * 100 + 100;

    return `${previous}
    [data-scroll-animation-delay='${value}'] { animation-delay: ${value}ms; }
  `;
  }, '')
  .trim();

/**
 * `scrollAnimations` config.
 */

const scrollAnimations = [
  {
    animation: clipFrom,
    defaultStyle: `
    --animation-clip-from-value: 100% 0 0 0;
    clip-path: inset(var(--animation-clip-from-value));
    will-change: clip-path;
  `,
    name: 'clip-up'
  },
  {
    animation: clipFrom,
    defaultStyle: `
    --animation-clip-from-value: 0 100% 0 0;
    clip-path: inset(var(--animation-clip-from-value));
    will-change: clip-path;
  `,
    name: 'clip-left'
  },
  {
    animation: fadeFrom,
    defaultStyle: `
    --animation-fade-from-value: 0, max(20%, 80px);
    opacity: 0;
    transform: translate(var(--animation-fade-from-value));
    will-change: opacity, transform;
  `,
    name: 'fade-in-up'
  },
  {
    animation: fadeFrom,
    defaultStyle: `
    --animation-fade-from-value: max(20%, 80px), 0;
    opacity: 0;
    transform: translate(var(--animation-fade-from-value));
    will-change: opacity, transform;
  `,
    name: 'fade-in-left'
  }
] as const;

/**
 *
 * Export `GlobalStyle`.
 */

export const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  ${rootVariables}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
  }

  body {
    ${textStyles.paragraph}

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: var(--color-gray200);
    color: var(--color-text-color);
    scrollbar-color: var(--scrollbar-thumb-background) var(--scrollbar-background);
    scrollbar-width: thin;
    text-rendering: optimizeLegibility;

    /* stylelint-disable */
    ::-webkit-scrollbar {
      width: var(--scrollbar-width);
    }

    ::-webkit-scrollbar-track {
      background: var(--scrollbar-background);
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-thumb-background);
      border: calc(var(--scrollbar-thumb-width) / 2) solid var(--scrollbar-background);
      border-radius: var(--scrollbar-border-radius);
    }
    /* stylelint-enable */
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  address {
    font-style: normal;
  }

  b,
  strong {
    font-weight: 600;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    appearance: none !important;
    background: none !important;
    background-color: transparent !important;
    transition: color 9999s ease-out, background-color 9999s ease-out;
    transition-delay: 9999s;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  /* stylelint-disable */
  ::selection,
  ::-moz-selection {
    background-color: #afd4ce;
  }
  /* stylelint-enable */

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .has-scroll-init {
    .c-scrollbar_thumb {
      background-color: var(--scrollbar-thumb-background);
      border-radius: var(--scrollbar-border-radius);
      width: var(--scrollbar-thumb-width);
    }

    .c-scrollbar {
      background-color: var(--scrollbar-background);
      width: var(--scrollbar-width);
    }

    ${
      scrollAnimations.reduce(
        (previous, config) =>
          css`
            ${previous}
            [data-scroll='${config.name}'] {
              animation-duration: 0.5s;
              animation-fill-mode: both;
              animation-timing-function: var(--transition-animation);
              ${config.defaultStyle}

              &.is-inview {
                animation-name: ${config.animation};
              }
            }
          ` as any,
        ''
      ) as any
}

    ${animationDelays}
  }
`;
