/**
 * Module dependencies.
 */

import { Context, ReactNode, createContext, useContext, useMemo } from 'react';
import { defaultLocale } from 'src/core/constants/locales';
import { usePreviousState } from 'src/hooks/use-previous-state';
import { useRouteResolver } from 'src/hooks/use-route-resolver';
import { useRouter } from 'next/router';

/**
 * `ContextProps` type.
 */

type ContextProps = {
  previousRoute: string;
};

/**
 * `ProviderProps` type.
 */

type ProviderProps = {
  children: ReactNode;
};

/**
 * `RouteHistoryContext` context.
 */

const RouteHistoryContext: Context<ContextProps> = createContext(
  {} as ContextProps
);

/**
 * Export `RouteHistoryProvider` provider.
 */

export const RouteHistoryProvider = ({ children }: ProviderProps) => {
  const { asPath, locale } = useRouter();
  const route = usePreviousState(asPath);
  const routeResolver = useRouteResolver();
  const previousRoute = useMemo(() => {
    if (route && route !== asPath) {
      return defaultLocale === locale ? route : `/${locale}${route}`;
    }

    return routeResolver('home');
  }, [asPath, locale, route, routeResolver]);

  return (
    <RouteHistoryContext.Provider value={{ previousRoute }}>
      {children}
    </RouteHistoryContext.Provider>
  );
};

/**
 * Export `useRouterHistory` hook.
 */

export const useRouterHistory = () => useContext(RouteHistoryContext);
