/**
 * Module dependencies.
 */

import { Footer } from './footer';
import { LayoutType, NavbarTheme } from 'src/types/app';
import { Locale } from 'i18n-routes';
import { LocomotiveScrollProvider, Scroll } from 'react-locomotive-scroll';
import { Navbar } from 'src/components/layout/navbar';
import { NavbarThemeProvider } from 'src/providers/navbar-theme';
import { PageTransition } from './page-transition';
import {
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';

import { TriggerNavbarTheme } from 'src/components/layout/navbar/trigger-navbar-theme';
import { cookieLocale } from 'src/core/constants/locales';
import { parseCookies, setCookie } from 'nookies';
import { useRouter } from 'next/router';
// @ts-ignore
import locomotiveScrollStyles from 'locomotive-scroll/dist/locomotive-scroll.css';
import styled, { createGlobalStyle } from 'styled-components';

/**
 * `Props` props.
 */

type Props = {
  children: ReactNode;
  hideFooter?: boolean;
  hideNavigation?: boolean;
  layout?: LayoutType;
  navbarDefaultTheme?: NavbarTheme;
};

/**
 * `MainContentProps` type.
 */

type MainContentProps = {
  children: ReactNode;
  hasFooter: boolean;
};

/**
 * `locomotiveConfig` constant.
 */

const locomotiveConfig = {
  reloadOnContextChange: true,
  smartphone: {
    smooth: false
  },
  smooth: true,
  tablet: {
    smooth: true
  }
} as const;

/**
 * `GlobalStyle` styled component.
 */

const GlobalStyle = createGlobalStyle`
  ${locomotiveScrollStyles}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  perspective: 1px;
`;

/**
 * `MainContent` component.
 */

const MainContent = forwardRef((props: MainContentProps, ref?: any) => {
  const { children, hasFooter } = props;

  return (
    <Content
      data-scroll-container
      id={'scroll-container'}
      ref={ref}
    >
      <main>{children}</main>

      {hasFooter && (
        <>
          <TriggerNavbarTheme theme={'dark'} />

          <Footer />
        </>
      )}
    </Content>
  );
});

/**
 * `MainContent` display name.
 */

MainContent.displayName = 'MainContent';

/**
 * Export `Layout` component.
 */

export const Layout = (props: Props) => {
  const { children, hideFooter, hideNavigation, navbarDefaultTheme } = props;
  const router = useRouter();
  const locale = useMemo(() => router?.locale as Locale, [router?.locale]);
  const locomotiveContainerRef = useRef<HTMLElement>();
  const handleLocationChange = useCallback((scroll: Scroll) => {
    try {
      return scroll?.scrollTo(0, { disableLerp: true, duration: 0 });
    } catch (error) {
      return;
    }
  }, []);

  useEffect(() => {
    const cookies = parseCookies();

    if (cookies[cookieLocale] === locale) {
      return;
    }

    setCookie(null, cookieLocale, locale, {
      maxAge: 365 * 24 * 60 * 60 * 1000,
      path: '/',
      secure: true
    });
  }, [locale]);

  return (
    <LocomotiveScrollProvider
      containerRef={locomotiveContainerRef}
      location={router.asPath}
      onLocationChange={handleLocationChange}
      options={locomotiveConfig}
      watch={[router.asPath]}
    >
      <NavbarThemeProvider>
        <GlobalStyle />

        <PageTransition navbarTheme={navbarDefaultTheme ?? 'dark'}>
          <Navbar hasNavigation={!hideNavigation} />

          <MainContent
            hasFooter={!hideFooter}
            ref={locomotiveContainerRef}
          >
            {children}
          </MainContent>
        </PageTransition>
      </NavbarThemeProvider>
    </LocomotiveScrollProvider>
  );
};
