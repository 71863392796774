/**
 * Module dependencies.
 */

import { Controller, UseControllerProps } from 'react-hook-form';
import { ForwardedRef, forwardRef } from 'react';
import { Input, InputProps } from './index';
import { assignRefs } from 'src/core/utils/refs';
import { useTranslation } from 'next-i18next';

/**
 * `Props` type.
 */

type Props = UseControllerProps & InputProps;

/**
 * Export `InputFieldComponent` component.
 */

export function InputFieldComponent(
  props: Props,
  forwardedRef: ForwardedRef<any>
) {
  const { t } = useTranslation();
  const {
    control,
    defaultValue = '',
    name,
    rules,
    shouldUnregister,
    ...inputProps
  } = props;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => (
        <Input
          {...inputProps}
          {...field}
          {...fieldState}
          error={t(fieldState?.error?.message as string)}
          name={name}
          ref={assignRefs<HTMLInputElement>(field?.ref, forwardedRef) as any}
        />
      )}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  );
}

/**
 * Export `InputField`.
 */

export const InputField = forwardRef<Props, any>(InputFieldComponent);
