/**
 * Module dependencies.
 */

import { axiosInstance, getApiEndpoint } from 'src/core/requests';
import { snakeCaseObjectKeys } from 'src/core/utils/objects';

/**
 * Export `NewsletterFormData` type.
 */

export type NewsletterFormData = {
  email: string;
  recaptchaToken: string;
};

/**
 * Export `subscribeNewsletter` request.
 */

export async function subscribeNewsletter(data: NewsletterFormData) {
  const response = await axiosInstance.post(
    getApiEndpoint('newsletter'),
    snakeCaseObjectKeys(data)
  );

  return response?.data?.data;
}
