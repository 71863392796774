/**
 * Module dependencies.
 */

import { useEffect, useRef } from 'react';

/**
 * Export `usePreviousState` hook.
 */

export function usePreviousState<T>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
