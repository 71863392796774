/**
 * Module dependencies.
 */

import { Locale } from 'i18n-routes';

/**
 * Export `getHeadersWithLocale` util.
 */

export function getHeadersWithLocale(locale: Locale) {
  if (!locale) {
    return;
  }

  return {
    headers: {
      // 'Accept-Encoding': '*',
      language: locale
    }
  };
}
