/**
 * Module dependencies
 */

import format from 'date-fns/format';

/**
 * `dateFormat` constant.
 */

const dateFormat = 'dd/MM/yyyy';

/**
 * Export `formatDate` util.
 */

export function formatDate(date: Date | string): string {
  try {
    if (typeof date === 'string') {
      return format(new Date(date), dateFormat);
    }

    return format(date, dateFormat);
  } catch (error) {
    return 'date-error';
  }
}
