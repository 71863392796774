/**
 * Export `i18nRoutes`.
 */

module.exports = {
  en: {
    allServices: '/services/all',
    appointment: '/book-an-appointment',
    checkUp: '/book-check-up',
    contacts: '/contacts',
    fitness: '/fitness',
    home: '/',
    magazine: '/magazine',
    magazineDetails: '/magazine/:slug',
    service: '/service/:slug',
    serviceAppointment: '/service/:slug/book-an-appointment',
    serviceType: '/service-type/:slug',
    services: '/services/:subCategory',
    team: '/team',
    teamMember: '/team/:slug',
    whatWeDo: '/what-we-do',
    whereWeAre: '/where-we-are',
    whoWeAre: '/who-we-are'
  },
  pt: {
    allServices: '/servicos/todos',
    appointment: '/marcar-consulta',
    checkUp: '/marcar-avaliacao',
    contacts: '/contactos',
    fitness: '/fitness',
    home: '/',
    magazine: '/magazine',
    magazineDetails: '/magazine/:slug',
    service: '/servico/:slug',
    serviceAppointment: '/servico/:slug/marcar-consulta',
    serviceType: '/tipo-de-servico/:slug',
    services: '/servicos/:subCategory',
    team: '/equipa',
    teamMember: '/equipa/:slug',
    whatWeDo: '/o-que-fazemos',
    whereWeAre: '/onde-estamos',
    whoWeAre: '/quem-somos'
  }
};
