/**
 * Export `gradients`.
 */

export const gradients = {
  'animated-text': `
    linear-gradient(
      68.09deg,
      var(--color-sage700) 0%,
      var(--color-blue500) 50%,
      var(--color-sage500) 75%,
      var(--color-cappuccino500) 100%
    )
  `,
  'header-overlay': `linear-gradient(180deg, rgba(82, 163, 150, 0.9) 0%, rgba(97, 166, 195, 0) 100%)`,
  menu: `linear-gradient(67.3deg, var(--color-blue500) 0%, var(--color-sage500) 124%);`,
  primary: `linear-gradient(67deg, var(--color-blue500) -38%, var(--color-sage500) 100%)`,
  team: `linear-gradient(
    68deg,
    var(--color-blue500) -8%,
    var(--color-sage500) 47%,
    var(--color-cappuccino500) 100%
  )
  `
};

export const textGradient = `
  display: block;
  color: var(--color-sage500);

  @supports ((background-clip: text) or (-webkit-background-clip: text)) and
    ((text-fill-color: transparent) or (-webkit-text-fill-color: transparent)) {
    -webkit-background-clip: text;
    background-clip: text;
    background-image: var(--gradient-team);
    background-size: cover;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;

    :is(a) {
      background-position: 100% 0%;
      background-image: var(--gradient-animated-text);
      background-size: 200% 100%;

      :hover,
      :focus {
        background-position: 0% 0%;
        background-size: 300% 100%;
      }
    }
  }
`;
