/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import {
  CSSTransition,
  Transition,
  TransitionStatus
} from 'react-transition-group';

import { FieldsGrid } from 'src/components/forms/shared/styles';
import { InputField } from 'src/components/core/forms/input/field';
import { NewsletterAndTermsFields } from '../forms/shared/newsletter-terms-fields';
import { NewsletterFormData } from 'src/requests/newsletter';
import { NewsletterPopup } from 'src/types/newsletter-popup';
import { RawHtml } from 'src/components/core/raw-html';
import { RecaptchaField } from 'src/components/core/forms/recaptcha';
import { RoundButton } from 'src/components/core/buttons/round-button';
import { Text } from 'src/components/core/text';
import { defaultLocale } from 'src/core/constants/locales';
import { emailRules } from 'src/core/forms/rules';
import { getNewsletterPopup } from 'src/requests/newsletter-popup';
import { media } from 'src/styles/utils/media';
import { switchProp } from 'styled-tools';
import { useBreakpoint } from '@untile/react-components/dist/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import closeSvg from 'src/assets/svg/close.svg';
import iconButton from 'src/assets/svg/button.svg';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * Step transition duration;
 */

const stepTransitionDuration = 500;

/**
 * `Props` type.
 */

type Props = {
  isOpen: boolean;
  isPromoCodeVisible: boolean;
  onClose: () => void;
  onSubmit: (data: NewsletterFormData, reset: () => void) => Promise<any>;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ transitionState: TransitionStatus }>`
  left: 50%;
  opacity: 0;
  overflow: hidden;
  padding: 0 1rem;
  pointer-events: none;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: var(--transition-default);
  transition-property: opacity, transform;
  width: 100%;
  z-index: var(--z-index-cookies-banner);

  ${switchProp('transitionState', {
    entered: `
      opacity: 1;
      transform: translate(-50%, -50%);
    `,
    entering: `
      opacity: 0;
      transform: translate(-50%, -40%);
    `,
    exited: `
      opacity: 0;
      transform: translate(-50%, -50%);
    `,
    exiting: `
      opacity: 0;
      transform: translate(-50%, -40%);
    `
  })}
`;

/**
 * `InnerWrapper` styled component.
 */

const InnerWrapper = styled.div`
  background-color: var(--color-white);
  border-radius: 4px;
  box-shadow: var(--shadow-medium);
  display: grid;
  grid-gap: 1rem;
  margin: 0 auto;
  max-width: 492px;
  padding: 1rem;
  pointer-events: auto;
  position: relative;

  ${media.min.md`
    padding: 1rem 1.5rem 1.5rem;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text)`
  padding-right: 2.5rem;

  ${media.min.md`
    padding-right: 4rem;
  `}
`;

/**
 * `Form` styled component.
 */

const Form = styled.form`
  opacity: 1;

  &.exit {
    opacity: 0;
    transition: opacity ${stepTransitionDuration}ms var(--transition-animation);
  }
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled(RoundButton).attrs({
  colorTheme: 'greenOutlined',
  icon: closeSvg
})`
  border: none;
  color: currentColor;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0.75);
`;

/**
 * `PromoCodeWrapper` styled component.
 */

const PromoCodeWrapper = styled.div`
  color: var(--color-sage700);

  &.enter {
    opacity: 0;
    transform: translateY(-10%);
  }

  &.enter-done {
    opacity: 1;
    transform: translateY(0);
    transition-duration: ${stepTransitionDuration}ms;
    transition-property: opacity, transform;
    transition-timing-function: var(--transition-animation);
  }
`;

/**
 * Export `NewsletterPopup` component.
 */

export function NewsletterPopup({
  isOpen,
  isPromoCodeVisible,
  onClose,
  onSubmit,
  ...rest
}: Props) {
  const { t } = useTranslation('common');
  const isMobile = useBreakpoint('ms', 'max');
  const promoCodeRef = useRef(null);
  const [newsletterData, setNewsletterData] = useState<
    NewsletterPopup | undefined
  >();

  const isNewsletterPopupActive = newsletterData?.isActive || undefined;
  const promoCode = newsletterData?.promoCode?.code;
  const { control, formState, handleSubmit, reset } =
    useForm<NewsletterFormData>({
      mode: 'onSubmit'
    });

  const handleOnSubmit = useCallback(
    (data: NewsletterFormData) => {
      return onSubmit(data, reset);
    },
    [onSubmit, reset]
  );

  useEffect(() => {
    const fetchNewsletterPopupData = async () => {
      const newsletterPopupData = await getNewsletterPopup(defaultLocale);

      return setNewsletterData(newsletterPopupData);
    };

    fetchNewsletterPopupData();
  }, []);

  if (isEmpty(newsletterData) || !promoCode || !isNewsletterPopupActive) {
    return null;
  }

  return (
    <Transition
      in={isOpen}
      mountOnEnter
      timeout={350}
      unmountOnExit
    >
      {(state: TransitionStatus) => (
        <Wrapper transitionState={state}>
          <InnerWrapper>
            <CloseButton
              onClick={onClose}
              size={isMobile ? 'small' : 'medium'}
            />

            <Title variant={'h3'}>{newsletterData?.title}</Title>

            <Text
              {...(!isMobile && { style: { marginBottom: '1rem' } })}
              variant={isMobile ? 'small' : 'paragraph'}
            >
              {newsletterData?.subtitle}
            </Text>

            <CSSTransition
              in={isPromoCodeVisible}
              mountOnEnter
              nodeRef={promoCodeRef}
              timeout={stepTransitionDuration}
            >
              <PromoCodeWrapper ref={promoCodeRef}>
                <Text
                  as={'p'}
                  style={{
                    color: '--color-sage700',
                    marginBottom: isMobile ? '0.5rem' : '2rem'
                  }}
                  variant={'h3'}
                >
                  {promoCode}
                </Text>

                <Text
                  as={'p'}
                  variant={isMobile ? 'small' : 'paragraph'}
                >
                  <RawHtml>
                    {t('common:newsletterPopup.successMessage', {
                      promoCode
                    })}
                  </RawHtml>
                </Text>
              </PromoCodeWrapper>
            </CSSTransition>

            <CSSTransition
              in={!isPromoCodeVisible}
              timeout={stepTransitionDuration}
              unmountOnExit
            >
              <Form onSubmit={handleSubmit(handleOnSubmit)}>
                <FieldsGrid {...rest}>
                  <InputField
                    control={control}
                    disabled={formState.isSubmitting}
                    label={t('footer.newsletter.label')}
                    labelSize={'small'}
                    name={'email'}
                    placeholder={t('footer.newsletter.email')}
                    rules={emailRules(t)}
                    size={'small'}
                  />

                  <NewsletterAndTermsFields
                    control={control}
                    disabled={formState.isSubmitting}
                    size={isMobile ? 'small' : 'medium'}
                  />

                  <RecaptchaField
                    action={'subscribePopup'}
                    control={control as any}
                  />

                  <div>
                    <Button
                      colorTheme={'green'}
                      icon={iconButton}
                      isLoading={formState.isSubmitting}
                      stretch
                      style={{ textTransform: 'capitalize' }}
                      type={'submit'}
                    >
                      {t('actions.subscribe')}
                    </Button>
                  </div>
                </FieldsGrid>
              </Form>
            </CSSTransition>
          </InnerWrapper>
        </Wrapper>
      )}
    </Transition>
  );
}
