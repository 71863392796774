/**
 * Module dependencies
 */

import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

/**
 * `isObject` util.
 */

function isObject(value: any) {
  return typeof value === 'object' && !Array.isArray(value);
}

/**
 * `transformObjectKeys` util.
 */

function transformObjectKeys<T = any>(
  data: any,
  transformer: (value: string) => string
): T {
  if (typeof data !== 'object') {
    return data;
  }

  try {
    return Object.keys(data).reduce((acc, key) => {
      const value = data?.[key];
      const normalizeObject =
        isObject(value) && transformObjectKeys(value, transformer);
      const normalizedArrayValue =
        Array.isArray(value) &&
        value.every(isObject) &&
        value.map(value => transformObjectKeys(value, transformer));

      return {
        ...acc,
        [transformer(key)]: normalizeObject || normalizedArrayValue || value
      };
    }, {}) as T;
  } catch (error) {
    return data;
  }
}

/**
 * Export `snakeCaseObjectKeys` function
 */

export function snakeCaseObjectKeys<T>(data: any) {
  return transformObjectKeys<T>(data, snakeCase);
}

/**
 * Export `camelCaseObjectKeys` function
 */

export function camelCaseObjectKeys<T>(data: any) {
  return transformObjectKeys<T>(data, camelCase);
}

/**
 * Export `getKeyFromObject`.
 */

export function getKeyFromObject(
  object: Record<string, unknown>,
  selectedValue: any
) {
  return Object.entries(object).find(
    ([, value]) => value === selectedValue
  )?.[0];
}
