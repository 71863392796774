/**
 * Module dependencies.
 */

import { containerMaxWidth } from 'src/styles/variables';
import { ifNotProp } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `Container` component.
 */

export const Container = styled.div<{ fluid?: boolean }>`
  padding: 0 var(--gutter);
  position: relative;
  width: 100%;

  ${ifNotProp(
    'fluid',
    css`
      margin-left: auto;
      margin-right: auto;
      max-width: var(--container-max-width);
    `
  )}

  @media (min-width: ${containerMaxWidth}) {
    --gutter: calc(var(--container-max-width) / 12);
  }
`;
