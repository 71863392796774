/**
 * Export `endpoints`.
 */

export const endpoints = {
  appointment: '/forms/appointment',
  checkUp: '/forms/check-up',
  contactUs: '/forms/contact-us',
  conveniences: '/conveniences',
  home: '/homepage',
  magazine: '/magazine',
  magazineBySlug: '/magazine/:slug',
  newsletter: '/forms/newsletter',
  newsletterPopup: '/forms/newsletter-popup',
  promoCode: '/forms/promo-code',
  service: '/services/:slug',
  services: '/services',
  team: '/staff-members',
  teamMember: '/staff-members/:slug',
  testimonials: '/testimonials',
  whatWeDoItems: '/what-we-do-items'
};
