/**
 * Module dependencies.
 */

import { Locale } from 'i18n-routes';
import {
  serviceAreaEnum,
  serviceCategoryEnum,
  serviceGenderEnum,
  serviceTypeEnum
} from './enums';

/**
 * Export `i18nServicesRoutes`.
 */

export const i18nServicesRoutes: Record<Locale, Record<string, string>> = {
  en: {
    [serviceAreaEnum.body]: 'body',
    [serviceAreaEnum.breast]: 'breast',
    [serviceAreaEnum.face]: 'face',
    [serviceAreaEnum.genital]: 'genital',
    [serviceAreaEnum.hair]: 'hair',
    [serviceAreaEnum.mind]: 'mind',
    [serviceAreaEnum.oculoplastic]: 'oculoplastic',
    [serviceCategoryEnum.area]: 'area',
    [serviceCategoryEnum.gender]: 'gender',
    [serviceCategoryEnum.type]: 'type',
    [serviceGenderEnum.man]: 'man',
    [serviceGenderEnum.woman]: 'woman',
    [serviceTypeEnum.aesthetic]: 'aesthetic',
    [serviceTypeEnum.healthWellness]: 'health-wellness',
    [serviceTypeEnum.mindfulness]: 'mindfulness'
  },
  pt: {
    [serviceAreaEnum.body]: 'corpo',
    [serviceAreaEnum.breast]: 'mama',
    [serviceAreaEnum.face]: 'face',
    [serviceAreaEnum.genital]: 'genital',
    [serviceAreaEnum.hair]: 'capilar',
    [serviceAreaEnum.mind]: 'mente',
    [serviceAreaEnum.oculoplastic]: 'oculoplastica',
    [serviceCategoryEnum.area]: 'zona',
    [serviceCategoryEnum.gender]: 'genero',
    [serviceCategoryEnum.type]: 'tipo',
    [serviceGenderEnum.man]: 'homem',
    [serviceGenderEnum.woman]: 'mulher',
    [serviceTypeEnum.aesthetic]: 'estetica',
    [serviceTypeEnum.healthWellness]: 'saude-e-bem-estar',
    [serviceTypeEnum.mindfulness]: 'mindfulness'
  }
};
