/* eslint-disable sort-keys */
/**
 * Export `schema`.
 */

export const schema = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  address: {
    '@type': 'PostalAddress',
    name: 'Ousia Clinic Postal Address',
    addressCountry: 'http://www.wikidata.org/entity/Q45',
    addressLocality: 'Lisboa',
    addressRegion: 'Centro',
    postalCode: '1400-342',
    streetAddress: 'Av. Torre de Belém, 17',
    '@id': 'https://ousiaclinic.com/#PostalAddress'
  },
  location: {
    '@id': 'https://ousiaclinic.com/#PostalAddress'
  },
  contactPoint: {
    '@type': 'ContactPoint',
    name: 'Ousia Clinic Contact Point',
    telephone: process.env.NEXT_PUBLIC_OUSIA_PHONE_NUMBER,
    availableLanguage: ['pt-PT'],
    '@id': 'https://ousiaclinic.com/#ContactPoint'
  },
  name: 'Ousia Clinic',
  keywords: [],
  email: process.env.NEXT_PUBLIC_OUSIA_EMAIL,
  sameAs: [
    process.env.NEXT_PUBLIC_FACEBOOK_URL,
    process.env.NEXT_PUBLIC_INSTAGRAM_URL,
    process.env.NEXT_PUBLIC_LINKEDIN_URL
  ],
  description: '',
  url: 'https://ousiaclinic.com',
  logo: 'https://ousiaclinic.com/images/logo.png',
  alternateName: 'ousiaclinic.com',
  telephone: process.env.NEXT_PUBLIC_OUSIA_PHONE_NUMBER,
  slogan: '',
  '@id': 'https://ousiaclinic.com/'
};
