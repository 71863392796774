/**
 * Module dependencies.
 */

import { AppProps } from 'src/types/app';
import { CookiesBanner } from 'src/components/layout/cookies-banner';
import { DefaultSeo } from 'next-seo';
import { GlobalStyle } from 'src/styles/global';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GridDebug } from 'src/components/core/debug/grid';
import { Layout } from 'src/components/layout';
import { RouteHistoryProvider } from 'src/providers/route-history';
import { ToastProvider } from 'src/providers/toast';
import { appWithTranslation } from 'next-i18next';
import { schema } from 'src/core/constants/schema';
import { seoDefaultConfig } from 'src/core/constants/seo-default-config';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Script from 'next/script';

/**
 * Google analytics id.
 */

const googleAnalyticsId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;

/**
 * Facebook pixel id.
 */

const facebookPixelId = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

/**
 * `App` component.
 */

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (googleAnalyticsId && window?.dataLayer) {
        window?.dataLayer.push({
          event: 'pageview',
          page: url
        });
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (facebookPixelId && window?.fbq) {
        window.fbq('track', 'PageView');
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta
          content={'IE=edge'}
          httpEquiv={'X-UA-Compatible'}
        />

        <meta
          content={'text/html;charset=utf-8'}
          httpEquiv={'Content-Type'}
        />

        <meta
          content={'width=device-width, initial-scale=1'}
          name={'viewport'}
        />

        <meta
          content={'true'}
          name={'HandheldFriendly'}
        />

        <link
          href={'/favicon.ico'}
          rel={'shortcut icon'}
          type={'image/x-icon'}
        />

        <link
          href={'/favicons/apple-touch-icon.png'}
          rel={'apple-touch-icon'}
          sizes={'180x180'}
        />

        <link
          href={'/favicons/favicon-32x32.png'}
          rel={'icon'}
          sizes={'32x32'}
          type={'image/png'}
        />

        <link
          href={'/favicons/favicon-16x16.png'}
          rel={'icon'}
          sizes={'16x16'}
          type={'image/png'}
        />

        <link
          href={'/site.webmanifest'}
          rel={'manifest'}
        />

        <link
          color={'#69aea3'}
          href={'/favicons/safari-pinned-tab.svg'}
          rel={'mask-icon'}
        />

        <meta
          content={'#f6f5f4'}
          name={'msapplication-TileColor'}
        />

        <meta
          content={'#x'}
          name={'theme-color'}
        />
      </Head>

      <Script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        id={'schema'}
        strategy={'beforeInteractive'}
        type={'application/ld+json'}
      />

      {googleAnalyticsId && (
        <Script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line id-match
            __html: `
            // Define dataLayer and the gtag function.
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }

            // Default all consent categories to 'denied'
            gtag('consent', 'default', {
              ad_storage: "denied",
              analytics_storage: "denied",
              functionality_storage: "denied",
              personalization_storage: "denied",
              security_storage: "denied",
              ad_user_data: "denied",
              ad_personalization: "denied",
              wait_for_update: 500
            });
            `
          }}
          id={'default-consent'}
          type={'text/javascript'}
        />
      )}

      {googleAnalyticsId && (
        <Script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${googleAnalyticsId}');
              `
          }}
          id={'google-tag-manager'}
          strategy={'afterInteractive'}
        />
      )}

      {facebookPixelId && (
        <Script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${facebookPixelId}');
              `
          }}
          id={'facebook-pixel'}
          strategy={'afterInteractive'}
        />
      )}

      <DefaultSeo {...seoDefaultConfig} />

      <GlobalStyle />

      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_SITE_KEY as string}
        scriptProps={{ async: true }}
      >
        {process.env.NEXT_PUBLIC_DEBUG_GRID === 'true' && (
          <GridDebug
            columns={12}
            gap={1}
          />
        )}

        <RouteHistoryProvider>
          <Layout
            hideFooter={pageProps?.hideFooter}
            hideNavigation={pageProps?.hideNavigation}
            navbarDefaultTheme={pageProps?.navbarTheme ?? 'dark'}
          >
            <Component {...pageProps} />
          </Layout>
        </RouteHistoryProvider>

        <CookiesBanner />
      </GoogleReCaptchaProvider>

      <ToastProvider />
    </>
  );
}

/**
 * Export `App` component.
 */

export default appWithTranslation(App);
