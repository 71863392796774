/**
 * Module dependencies.
 */

import { Transition, TransitionStatus } from 'react-transition-group';
import { switchProp } from 'styled-tools';
import { useNavbarTheme } from 'src/providers/navbar-theme';
import styled from 'styled-components';

/**
 * Constants.
 */

const durationMs = 250;

/**
 * `Props` type.
 */

type Props = {
  children: React.ReactNode;
  className?: string;
  isOpen: boolean;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ transitionState: TransitionStatus }>`
  opacity: 0;
  transition: opacity ${durationMs}ms ease-in-out;

  ${switchProp('transitionState', {
    entered: `
      opacity: 1;
      transition-delay: ${durationMs}ms;
    `,
    entering: `
      opacity: 1;
      transition-delay: ${durationMs}ms;
    `,
    exited: 'opacity: 0;',
    exiting: 'opacity: 0;'
  })}
`;

/**
 * Export `TransitionWrapper` component.
 */

export function TransitionWrapper(props: Props) {
  const { children, className, isOpen } = props;
  const { setTheme } = useNavbarTheme();

  return (
    <Transition
      in={isOpen}
      mountOnEnter
      onEntered={() => setTheme('dark')}
      timeout={durationMs}
      unmountOnExit
    >
      {(state: TransitionStatus) => (
        <Wrapper
          className={className}
          transitionState={state}
        >
          {children}
        </Wrapper>
      )}
    </Transition>
  );
}
