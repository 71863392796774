/**
 * Module dependencies.
 */

import { Locale, RouteName } from 'i18n-routes';
import { RouteResolveOptions, routeResolve } from 'src/core/utils/routes';
import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

/**
 * Export `useRouteResolver` hook.
 */

export function useRouteResolver() {
  const router = useRouter();
  const locale = useMemo(() => router?.locale as Locale, [router?.locale]);

  return useCallback(
    (
      route: RouteName,
      interpolations?: RouteResolveOptions['interpolations'] & {
        locale?: RouteResolveOptions['locale'];
      }
    ) => {
      return routeResolve(route, {
        interpolations,
        locale: (interpolations?.locale as Locale) ?? locale
      });
    },
    [locale]
  );
}
