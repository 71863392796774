/**
 * Module dependencies.
 */

import { ObjectIdType, algoliaObjectId } from 'src/core/constants/algolia';
import { Text } from 'src/components/core/text';
import { ifProp } from 'styled-tools';
import { useTranslation } from 'next-i18next';
import camelCase from 'lodash/camelCase';
import styled from 'styled-components';

/**
 * `getIndexName`.
 */

function getIndexName(objectID: string): ObjectIdType {
  if (objectID.includes(algoliaObjectId.staff)) {
    return 'staff';
  }

  if (objectID.includes(algoliaObjectId.magazine)) {
    return 'magazine';
  }

  return 'service';
}

/**
 * Export `useBreadcrumb` hook.
 */

export function useBreadcrumb(hit: any): string {
  const { t } = useTranslation();
  const indexName = getIndexName(hit?.objectID);

  if (indexName === 'staff') {
    return t('common:navbar.search.indexes.staffMembers');
  }

  if (indexName === 'magazine') {
    return `${t('common:navbar.search.indexes.magazinePosts')} > ${t(
      `common:service.subCategory.${camelCase(hit?.category)}`
    )}`;
  }

  return `${t('common:navbar.search.indexes.services')} > ${t(
    `common:service.category.${camelCase(hit?.category)}`
  )} > ${t(`common:service.subCategory.${camelCase(hit?.sub_category)}`)}`;
}

/**
 * Export `HitBreadcrumb` styled component.
 */

export const HitBreadcrumb = styled(Text).attrs({ variant: 'xsmall' })<{
  isLight?: boolean;
}>`
  color: ${ifProp('isLight', 'var(--color-white)', 'var(--color-black)')};
  display: block;
`;
