/**
 * Module dependencies.
 */

import { DefaultSeoProps } from 'next-seo';
import { absoluteUrlResolve } from 'src/core/utils/url';
import { colors } from 'src/styles/colors';
import packageJson from 'package.json';

/**
 * Export `seoDefaultConfig`.
 */

export const seoDefaultConfig: DefaultSeoProps = {
  additionalMetaTags: [
    {
      content: packageJson.version,
      name: 'version'
    },
    {
      content: 'Untile',
      name: 'author'
    }
  ],
  defaultTitle: 'Ousia',
  description: 'Ousia website description',
  openGraph: {
    images: [{ url: absoluteUrlResolve('/images/share-image.jpg') }],
    siteName: 'Ousia',
    url: process.env.NEXT_PUBLIC_BASE_URL
  },
  themeColor: colors.white,
  titleTemplate: '%s | Ousia'
};
