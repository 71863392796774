/**
 * Module dependencies.
 */

import { NavbarTheme } from 'src/types/app';
import { Waypoint } from 'react-waypoint';
import { useEffect, useState } from 'react';
import { useNavbarTheme } from 'src/providers/navbar-theme';

/**
 * `Props` type.
 */

type Props = {
  theme: NavbarTheme;
};

/**
 * `ActionArgs` type.
 */

type ActionArgs = Waypoint.CallbackArgs;

/**
 * Export `TriggerNavbarTheme` component.
 */

export function TriggerNavbarTheme({ theme }: Props) {
  const [mainId, setMainId] = useState<HTMLElement | null>(null);
  const { setTheme } = useNavbarTheme();

  useEffect(() => {
    setMainId(document?.getElementById('scroll-container'));
  }, []);

  return (
    <Waypoint
      fireOnRapidScroll
      onEnter={(args: ActionArgs) => {
        const { currentPosition, previousPosition } = args;
        const isAbove = previousPosition === Waypoint.above;
        const isInside = currentPosition === Waypoint.inside;

        if (isAbove && isInside) {
          setTheme(theme === 'dark' ? 'light' : 'dark');
        }
      }}
      onLeave={(args: ActionArgs) => {
        const { currentPosition, previousPosition } = args;
        const isAbove = currentPosition === Waypoint.above;
        const isInside = previousPosition === Waypoint.inside;

        if (isAbove && isInside) {
          setTheme(theme);
        }
      }}
      scrollableAncestor={mainId}
      topOffset={10}
    />
  );
}
