/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { useNavbarTheme } from 'src/providers/navbar-theme';
import { useRouteResolver } from 'src/hooks/use-route-resolver';
import { useTranslation } from 'next-i18next';
import svgButton from 'src/assets/svg/button.svg';

/**
 * `Props` type.
 */

type Props = {
  theme?: 'green' | 'white';
};

/**
 * Export `ContactButtons` component.
 */

export function ContactButtons({ theme }: Props) {
  const { t } = useTranslation();
  const navbar = useNavbarTheme();
  const resolveRoute = useRouteResolver();
  const linkColor = theme ?? (navbar.theme === 'light' ? 'green' : 'white');

  return (
    <>
      <Button
        colorTheme={`${linkColor}Outlined`}
        href={resolveRoute('contacts')}
        icon={svgButton}
      >
        {t('common:navbar.buttons.contacts')}
      </Button>

      <Button
        colorTheme={linkColor}
        href={resolveRoute('checkUp')}
        icon={svgButton}
      >
        {t('common:navbar.buttons.appointment')}
      </Button>
    </>
  );
}
