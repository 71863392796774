/**
 * Module dependencies.
 */

import { Locale } from 'i18n-routes';

/**
 * Export `defaultLocale`.
 */

export const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE as Locale;

/**
 * Export `locales`.
 */

export const locales = (process.env.NEXT_PUBLIC_LOCALES as string).split(
  ','
) as Locale[];

/**
 * Export `cookieLocale`.
 */

export const cookieLocale = 'NEXT_LOCALE';
