/**
 * Module dependencies.
 */

import { Text } from 'src/components/core/text';
import { media } from 'src/styles/utils/media';
import styled from 'styled-components';

/**
 * Export `Form` styled component.
 */

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

/**
 * Export `FieldsGrid` styled component.
 */

export const FieldsGrid = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 1rem;

  ${media.min.ms`
    grid-gap: 2rem;
  `}
`;

/**
 * Export `Title` styled component.
 */

export const Title = styled(Text).attrs({
  variant: 'h2'
})`
  color: var(--color-sage500);
  display: block;
  margin-bottom: 40px;
`;
