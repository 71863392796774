/**
 * Pallete.
 */

const pallete = {
  black: '#000000',
  blue300: '#c4dee9',
  blue400: '#97c4d8',
  blue500: '#61a6c3',
  blue600: '#4e9bbc',
  blue700: '#1e7fa8',
  cappuccino300: '#f6f5f4',
  cappuccino400: '#e3e1de',
  cappuccino500: '#c5c1bb',
  cappuccino600: '#4e9bbc',
  cappuccino700: '#8e877b',
  gray100: '#fcfcfc',
  gray200: '#f5f5f5',
  gray250: '#E2E2E2',
  gray300: '#d9d9d9',
  gray400: '#c0bdbd',
  gray500: '#afafaf',
  gray600: '#8a8a8a',
  gray700: '#636363',
  gray800: '#333333',
  gray900: '#131313',
  green300: '#ecfeee',
  green400: '#b2e8b8',
  green500: '#2cb53b',
  green600: '#088715',
  green700: '#005609',
  purple500: '#8b3cca',
  red300: '#fff6f6',
  red400: '#eeB7bb',
  red500: '#d64c56',
  red600: '#a60814',
  red700: '#73020a',
  sage300: '#afd4de',
  sage400: '#88bfb6',
  sage500: '#69aea3',
  sage600: '#52a296',
  sage700: '#158371',
  white: '#ffffff',
  yellow300: '#fffcf0',
  yellow400: '#fceaa9',
  yellow500: '#f8c71d',
  yellow600: '#ddaf0c',
  yellow700: '#a78407'
};

/**
 * Export `dolors`.
 */

export const colors = {
  ...pallete,
  'link-default': '#158371',
  'link-hover': '#055c4e',
  'link-visited': '#8b3ccaA',
  primary: pallete.sage500,
  secondary: pallete.blue500,
  'text-color': pallete.gray700
};
