/**
 * Module dependencies.
 */

import { ParsedUrlQuery } from 'querystring';
import { defaultLocale } from 'src/core/constants/locales';
import { getKeyFromObject } from './objects';
import { i18nServicesRoutes } from 'src/core/constants/i18n';
import { regexes } from 'src/core/constants/regexes';
import get from 'lodash/get';
import i18nRoutes, { Locale, RouteName } from 'i18n-routes';
import template from 'lodash/template';

/**
 * Export `RouteResolveOptions` type.
 */

export type RouteResolveOptions = {
  interpolations?: ParsedUrlQuery;
  locale: Locale;
};

/**
 * Base routes.
 */

const baseRoutes = i18nRoutes?.[defaultLocale];

/**
 * `normalizeRouteUrl` util.
 */

function normalizeRouteUrl(path: string) {
  return path.startsWith('/') ? path : `/${path}`;
}

/**
 * Export `routeResolve`.
 */

export function routeResolve(
  routeName: RouteName | string,
  options: RouteResolveOptions
): string {
  const { interpolations, locale } = options;
  const isRoute = Object.keys(baseRoutes).includes(routeName);
  const route = isRoute ? get(i18nRoutes, [locale, routeName]) : routeName;
  const path = defaultLocale === locale ? route : `${locale}${route}`;

  if (interpolations) {
    return normalizeRouteUrl(
      template(path, {
        interpolate: regexes.interpolateUrl
      })(interpolations)
    );
  }

  return normalizeRouteUrl(path);
}

/**
 * Export `getRoute`.
 */

export function getRoute(routeName: RouteName, locale: Locale) {
  return i18nRoutes?.[locale]?.[routeName];
}

/**
 * Export `getRouteByPathname`.
 */

export function getRouteByPathname(pathname: string): RouteName {
  const [path, params] = pathname.split('?');
  const parsedPath = path.replace(/\[(\w+)\]/g, ':$1');
  const resolvedPathname = parsedPath + (params ?? '');

  return getKeyFromObject(baseRoutes, resolvedPathname) as RouteName;
}

/**
 * Export `getLocalizedCategoryByServiceSlug`.
 */

export function getLocalizedCategoryByServiceSlug(
  locale: Locale,
  slug: string
) {
  return i18nServicesRoutes?.[locale]?.[slug];
}

/**
 * Export `getLocalizedCategoryByServicesSlug`.
 */

export function getLocalizedCategoryByServicesSlug(
  locale: Locale,
  slug: string
) {
  return getKeyFromObject(i18nServicesRoutes?.[locale], slug);
}
