/**
 * Module dependencies.
 */

import { ForwardedRef, forwardRef } from 'react';
import { assignRefs } from 'src/core/utils/refs';
import { ifProp } from 'styled-tools';
import NextImage, { ImageProps as NextImageProps } from 'next/image';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = NextImageProps & {
  className?: string;
};

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(NextImage)`
  ${ifProp('fill', 'object-fit: cover;')}
`;

function ImageComponent(props: Props, forwardedRef: ForwardedRef<any>) {
  return (
    <StyledImage
      {...props}
      ref={assignRefs(forwardedRef)}
    />
  );
}

/**
 * Export `Image`.
 */

export const Image = forwardRef<Props, any>(ImageComponent);

/**
 * Export `ParallaxImage` styled component.
 */

export const ParallaxImage = styled(Image).attrs({ fill: true })<{
  'data-scroll': boolean;
  'data-scroll-direction': 'horizontal' | 'vertical';
  'data-scroll-speed': number;
}>`
  height: 120% !important;
  inset: -10% !important;
  object-fit: cover;
  width: 120% !important;
`;
