/**
 * Module dependencies.
 */

import 'react-toastify/dist/ReactToastify.css';
import { ReactNode } from 'react';
import {
  Slide,
  ToastContainer,
  ToastOptions,
  toast as reactToast
} from 'react-toastify';

import { Svg } from 'src/components/core/svg';
import closeSvg from 'src/assets/svg/close.svg';
import errorSvg from 'src/assets/svg/alert-cross.svg';
import infoSvg from 'src/assets/svg/alert-info.svg';
import styled, { createGlobalStyle } from 'styled-components';
import successSvg from 'src/assets/svg/alert-check.svg';

/**
 * `Props` type.
 */

type Props = {
  children?: ReactNode;
};

/**
 * `Options` type.
 */

type Options = ToastOptions | undefined;

/**
 * `Message` type.
 */

type Message = string | ReactNode;

/**
 * `CloseButtonProps` type.
 */

type CloseButtonProps = {
  closeToast: (e: React.MouseEvent<HTMLElement>) => void;
};

/**
 * Toast global styles.
 */

const ToastGlobalStyles = createGlobalStyle`
  :root {
    --toastify-color-cross: var(--color-red500);
    --toastify-color-dark: var(--color-gray700);
    --toastify-color-info: var(--color-blue500);
    --toastify-color-light: var(--color-white);
    --toastify-color-success: var(--color-sage500);
    --toastify-font-family: var(--font-family-poppins);
    --toastify-icon-color-info: var(--color-white);
    --toastify-text-color-light: var(--color-white);
    --toastify-toast-width: clamp(320px, 40vw, 650px);
    --toastify-z-index: 999999;
  }

  .Toastify__toast {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    padding: 0;

    &-body {
      padding-left: 16px;
      white-space: pre-line;
      word-break: break-word;
    }

    .Toastify__close-button {
      align-items: center;
      align-self: center;
      color: inherit;
      display: flex;
      flex: 0 0 24px;
      justify-content: center;
      justify-self: center;
      padding: 12px;

      > svg {
        height: 24px;
        max-width: 24px;
        width: 24px;
      }
    }

    ${['info', 'error', 'success'].map(
    key => `
      &--${key} {
        background-color: var(--toastify-color-${key});
      }
    `
  )}
  }
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled.button`
  align-self: center;
  appearance: none;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  height: 48px;
  margin: 0;
  padding: 0;
  transition: var(--default-transition);
  width: 48px;

  &:focus,
  &:hover {
    opacity: 0.7;
  }
`;

/**
 * Alert Icons.
 */

const alertIcons = {
  error: errorSvg,
  info: infoSvg,
  success: successSvg
} as const;

/**
 * Default options.
 */

const defaultOptions = {
  icon: ({ type }: { type: keyof typeof alertIcons }): any => (
    <Svg
      icon={alertIcons?.[type]}
      size={'24px'}
    />
  )
};

/**
 * Export `toast` util.
 */

export const toast = {
  error: (message: Message, options?: Options) => {
    reactToast.error(message, { ...defaultOptions, ...options } as any);
  },
  info: (message: Message, options?: Options) => {
    reactToast.info(message, { ...defaultOptions, ...options } as any);
  },
  success: (message: Message, options?: Options) => {
    reactToast.success(message, { ...defaultOptions, ...options } as any);
  }
} as const;

/**
 * `CloseButton` component.
 */

function CloseButton({ closeToast }: CloseButtonProps) {
  return (
    <StyledButton onClick={closeToast}>
      <Svg
        icon={closeSvg}
        size={'48px'}
      />
    </StyledButton>
  );
}

/**
 * Export `ToastProvider` component.
 */

export function ToastProvider({ children }: Props) {
  return (
    <>
      {children}

      <ToastGlobalStyles />

      <ToastContainer
        autoClose={5000}
        closeButton={CloseButton}
        closeOnClick={false}
        hideProgressBar
        limit={4}
        newestOnTop
        position={reactToast.POSITION.BOTTOM_LEFT}
        transition={Slide}
      />
    </>
  );
}
