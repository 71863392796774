/**
 * Module dependencies
 */

import facebookSvg from 'src/assets/svg/facebook.svg';
import instagramSvg from 'src/assets/svg/instagram.svg';
import linkedinSvg from 'src/assets/svg/linkedin.svg';

/**
 * Social networks constants.
 */

export const socialNetworks = [
  {
    icon: facebookSvg,
    label: 'Facebook',
    url: process.env.NEXT_PUBLIC_FACEBOOK_URL
  },
  {
    icon: instagramSvg,
    label: 'Instagram',
    url: process.env.NEXT_PUBLIC_INSTAGRAM_URL
  },
  {
    icon: linkedinSvg,
    label: 'Linkedin',
    url: process.env.NEXT_PUBLIC_LINKEDIN_URL
  }
] as const;
