/**
 * Module dependencies.
 */

import { TFunction } from 'i18next';
import { formatDate } from 'src/core/utils/date';
import { regexes } from 'src/core/constants/regexes';
import isAfter from 'date-fns/isAfter';

/**
 * Export `requiredRule`.
 */

export const requiredRule = (translate: TFunction) => ({
  required: translate('common:error.form.required')
});

/**
 * Export `emailRules`.
 */

export const emailRules = (translate: TFunction) => ({
  ...requiredRule(translate),
  pattern: {
    message: translate('common:error.form.invalidEmail'),
    value: regexes.email
  }
});

/**
 * Export `nonEmpty`.
 */

export const nonEmpty = (translate: TFunction) => ({
  ...requiredRule(translate),
  validate: (value: string) => {
    return value.trim().length > 0 || translate('common:error.form.nonEmpty');
  }
});

/**
 * Export `minLength`.
 */

export const minLength = (translate: TFunction, minimum: number) => ({
  ...requiredRule(translate),
  ...nonEmpty(translate),
  minLength: {
    message: translate('common:error.form.minLength', { minimum }),
    value: minimum
  }
});

/**
 * Export `minDateRule`.
 */

export const minDateRule = (minDate: string, translate: TFunction) => ({
  ...requiredRule(translate),
  validate: (date: string) => {
    const minimumDate = new Date(minDate);
    const isAfterMinDate = isAfter(new Date(date), minimumDate);

    return (
      isAfterMinDate ||
      translate('common:error.form.minDate', {
        minDate: formatDate(minDate)
      })
    );
  }
});

/**
 * Export `maxDateRule`.
 */

export const maxDateRule = (maxDate: string, translate: TFunction) => ({
  ...requiredRule(translate),
  validate: (date: string) => {
    const maximumDate = new Date(maxDate);
    const isAfterMaxDate = isAfter(new Date(date), maximumDate);

    return (
      !isAfterMaxDate ||
      translate('common:error.form.maxDate', {
        maxDate: formatDate(maxDate)
      })
    );
  }
});
