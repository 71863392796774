/**
 * Module dependencies.
 */

import { FontProperties, setFontStyle } from 'src/styles/utils/typography';
import { ReactNode } from 'react';
import { ifProp, switchProp } from 'styled-tools';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

/**
 * `createFontStyle` util.
 */

const createFontStyle = (properties: FontProperties) => {
  if (isEmpty(properties) || !properties) {
    throw new Error(`🚨 No font properties provided.`);
  }

  return css`
    ${setFontStyle(properties)}

    margin: 0;
  `;
};

/**
 * `display1` styles.
 */

const display1 = {
  fontFamily: 'var(--font-family-playfair)',
  fontSize: 104,
  fontSizeMin: 48,
  fontWeight: 400,
  lineHeight: 104,
  lineHeightMin: 56
} as FontProperties;

/**
 * `display2` styles.
 */

const display2 = {
  fontFamily: 'var(--font-family-playfair)',
  fontSize: 88,
  fontSizeMin: 40,
  fontWeight: 400,
  lineHeight: 88,
  lineHeightMin: 48
} as FontProperties;

/**
 * `display3` styles.
 */

const display3 = {
  fontFamily: 'var(--font-family-poppins)',
  fontSize: 48,
  fontSizeMin: 32,
  fontWeight: 400,
  lineHeight: 56,
  lineHeightMin: 40
} as FontProperties;

/**
 * `display4` styles.
 */

const display4 = {
  fontFamily: 'var(--font-family-playfair)',
  fontSize: 64,
  fontSizeMin: 40,
  fontWeight: 400,
  lineHeight: 72,
  lineHeightMin: 48
} as FontProperties;

/**
 * `h1` styles.
 */

const h1 = {
  fontFamily: 'var(--font-family-playfair)',
  fontSize: 88,
  fontSizeMin: 40,
  fontWeight: 400,
  lineHeight: 96,
  lineHeightMin: 48
} as FontProperties;

/**
 * `h2` styles.
 */

const h2 = {
  fontFamily: 'var(--font-family-playfair)',
  fontSize: 56,
  fontSizeMin: 32,
  fontWeight: 400,
  lineHeight: 64,
  lineHeightMin: 40
} as FontProperties;

/**
 * `h3` styles.
 */

const h3 = {
  fontFamily: 'var(--font-family-playfair)',
  fontSize: 40,
  fontSizeMin: 24,
  fontWeight: 400,
  lineHeight: 48,
  lineHeightMin: 32
} as FontProperties;

/**
 * `h4` styles.
 */

const h4 = {
  fontFamily: 'var(--font-family-poppins)',
  fontSize: 32,
  fontSizeMin: 24,
  fontWeight: 600,
  lineHeight: 40,
  lineHeightMin: 32
} as FontProperties;

/**
 * `h5` styles.
 */

const h5 = {
  fontFamily: 'var(--font-family-poppins)',
  fontSize: 24,
  fontSizeMin: 20,
  fontWeight: 600,
  lineHeight: 32
} as FontProperties;

/**
 * `h6` styles.
 */

const h6 = {
  fontFamily: 'var(--font-family-poppins)',
  fontSize: 18,
  fontWeight: 400,
  lineHeight: 24
} as FontProperties;

/**
 * `menu` styles.
 */

const menu = {
  fontFamily: 'var(--font-family-poppins)',
  fontSize: 56,
  fontSizeMin: 24,
  fontWeight: 400,
  lineHeight: 64,
  lineHeightMin: 34
} as FontProperties;

/**
 * `submenu` styles.
 */

const submenu = {
  fontFamily: 'var(--font-family-poppins)',
  fontSize: 40,
  fontSizeMin: 24,
  fontWeight: 400,
  lineHeight: 56,
  lineHeightMin: 32
} as FontProperties;

/**
 * `subtitle1` styles.
 */

const subtitle1 = {
  fontFamily: 'var(--font-family-poppins)',
  fontSize: 32,
  fontSizeMin: 24,
  fontWeight: 400,
  italic: true,
  lineHeight: 48,
  lineHeightMin: 32
} as FontProperties;

/**
 * `subtitle2` styles.
 */

const subtitle2 = {
  fontFamily: 'var(--font-family-poppins)',
  fontSize: 24,
  fontSizeMin: 16,
  fontWeight: 400,
  lineHeight: 40,
  lineHeightMin: 24
} as FontProperties;

/**
 * `paragraph` styles.
 */

const paragraph = {
  fontFamily: 'var(--font-family-poppins)',
  fontSize: 18,
  fontWeight: 400,
  lineHeight: 32
} as FontProperties;

/**
 * `small` styles.
 */

const small = {
  fontFamily: 'var(--font-family-poppins)',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 24
} as FontProperties;

/**
 * `xsmall` styles.
 */

const xsmall = {
  fontFamily: 'var(--font-family-poppins)',
  fontSize: 10,
  fontWeight: 400,
  lineHeight: 16
} as FontProperties;

/**
 * Variants themes.
 */

export const textStyles = {
  display1: createFontStyle(display1),
  display2: createFontStyle(display2),
  display3: createFontStyle(display3),
  display4: createFontStyle(display4),
  h1: createFontStyle(h1),
  h2: createFontStyle(h2),
  h3: createFontStyle(h3),
  h4: createFontStyle(h4),
  h5: createFontStyle(h5),
  h6: createFontStyle(h6),
  menu: createFontStyle(menu),
  paragraph: createFontStyle(paragraph),
  small: createFontStyle(small),
  submenu: createFontStyle(submenu),
  subtitle1: createFontStyle(subtitle1),
  subtitle2: createFontStyle(subtitle2),
  xsmall: createFontStyle(xsmall)
} as const;

/**
 * `Props` type.
 */

type Props = {
  as?:
    | 'a'
    | 'address'
    | 'blockquote'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'small'
    | 'strong'
    | 'sup';
  block?: boolean;
  bold?: boolean;
  children: ReactNode;
  italic?: boolean;
  variant?: keyof typeof textStyles;
};

/**
 * Export `Text` styled component.
 */

export const Text = styled.span<Props>`
  ${switchProp('variant', textStyles, textStyles.paragraph)}

  ${ifProp('block', 'display: block;')}
  ${ifProp('bold', 'font-weight: 600;')}
  ${ifProp('italic', 'font-style: italic;')}
`;
