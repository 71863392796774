/**
 * Module dependencies.
 */

import { ifNotProp, ifProp, prop } from 'styled-tools';
import React from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` interface.
 */

type Props = {
  className?: string;
  disabled?: boolean;
  relative?: boolean;
  size?: number;
  stroke?: number;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.span<Pick<Props, 'disabled' | 'relative'>>`
  display: inline-block;
  opacity: ${ifProp('disabled', 0, 1)};
  pointer-events: none;
  transform: scale(${ifProp('disabled', 0.5, 1)});
  transition: var(--transition-default);
  transition-property: opacity, transform;

  ${ifNotProp(
    'relative',
    css`
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) scale(${ifProp('disabled', 0.5, 1)});
      z-index: 1;
    `
  )}
`;

/**
 * `Loader` styled component.
 */

const Loader = styled.span<Pick<Props, 'size' | 'stroke'>>`
  animation: spin 0.75s linear infinite;
  border-left-color: transparent;
  border-radius: ${prop('size')}px;
  border-style: solid;
  border-width: ${prop('stroke')}px;
  display: block;
  height: ${prop('size')}px;
  width: ${prop('size')}px;
`;

/**
 * Export `Loading` component.
 */

export function Loading(props: Props) {
  const { size = 24, stroke = 2, ...rest } = props;

  return (
    <Wrapper {...rest}>
      <Loader
        size={size}
        stroke={stroke}
      />
    </Wrapper>
  );
}
