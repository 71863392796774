/**
 * Module dependencies.
 */

import {
  Field,
  FormField,
  InputIcon,
  InputTheme,
  Label,
  Message
} from 'src/components/core/forms/styles';

import {
  ForwardedRef,
  InputHTMLAttributes,
  forwardRef,
  useCallback,
  useRef
} from 'react';

import { Loading } from 'src/components/core/loading';
import { Size } from 'src/types/styles';
import { assignRefs } from 'src/core/utils/refs';
import { switchProp } from 'styled-tools';
import { textStyles } from 'src/components/core/text';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * Export `InputProps` type.
 */

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  error?: string;
  icon?: string;
  iconLabel?: string;
  iconType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  info?: string;
  isLoading?: boolean;
  isRequired?: boolean;
  label?: string;
  labelSize?: Size;
  onIconClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: Size;
  theme?: InputTheme;
};

/**
 * `Sizes` constant.
 */

const sizes = {
  medium: `
    height: 48px;
  `,
  small: `
    height: 40px;
  `
};

/**
 * `InputElement` styled component.
 */

const InputElement = styled.input<Pick<InputProps, 'size'>>`
  ${textStyles.paragraph}

  background: none;
  border: none;
  color: var(--input-text-color);
  grid-area: input;
  margin: 0;
  padding: 0;
  width: 100%;

  ${switchProp('size', sizes, sizes.medium)}

  ::placeholder {
    color: var(--input-placeholder-color);
  }

  :focus {
    outline: none;
  }

  :is(textarea) {
    height: auto;
    min-height: 48px;
    resize: vertical;
  }

  :is([type='date']),
  :is([type='time']) {
    ::-webkit-inner-spin-button,
    ::-webkit-calendar-picker-indicator {
      appearance: none;
      display: none;
    }
  }
`;

/**
 * `IconWrapper` styled component.
 */

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  position: relative;
  width: 48px;
`;

/**
 * Export `InputComponent` component.
 */

export function InputComponent(
  props: InputProps,
  forwardedRef: ForwardedRef<any>
) {
  const {
    error,
    icon,
    iconLabel,
    iconType,
    info,
    isLoading,
    isRequired,
    label,
    labelSize,
    onIconClick,
    theme = 'dark',
    ...inputProps
  } = props;

  const inputRef = useRef<HTMLInputElement>();
  const getFocus = useCallback(() => {
    if (typeof inputRef.current?.focus === 'function') {
      inputRef.current?.focus();
    }
  }, []);

  return (
    <FormField
      disabled={inputProps.disabled || inputProps.readOnly}
      hasError={!!error}
      theme={theme}
    >
      {label && (
        <Label
          htmlFor={inputProps?.name}
          isRequired={!!isRequired}
          labelSize={labelSize}
        >
          {label}
        </Label>
      )}

      <Field
        data-has-value={!isEmpty(inputProps?.value)}
        hasIcon={!!icon}
      >
        <InputElement
          id={inputProps?.name}
          {...(inputProps as any)}
          ref={assignRefs(inputRef, forwardedRef)}
        />

        {(!!icon || isLoading) && (
          <IconWrapper>
            {isLoading ? (
              <Loading />
            ) : (
              <InputIcon
                aria-label={iconLabel ?? label}
                icon={icon}
                onClick={onIconClick ?? getFocus}
                type={iconType}
              />
            )}
          </IconWrapper>
        )}
      </Field>

      {(info || error) && (
        <Message type={error ? 'error' : 'info'}>{error || info}</Message>
      )}
    </FormField>
  );
}

/**
 * Export `Input`.
 */

export const Input = forwardRef<InputProps, any>(InputComponent);
