/**
 * Module dependencies.
 */

import { AllSearchResults, SearchHits } from 'src/types/search';
import { Button } from 'src/components/core/buttons/button';
import {
  Configure,
  Index,
  connectInfiniteHits,
  connectStateResults
} from 'react-instantsearch-dom';

import { HitBreadcrumb, useBreadcrumb } from './hit-breadcrumb';
import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from 'src/components/core/svg';
import { Text, textStyles } from 'src/components/core/text';
import {
  algoliaIndexesOrder,
  algoliaPaginationLimit,
  algoliaRoutesNames
} from 'src/core/constants/algolia';

import { ifProp } from 'styled-tools';
import { transparentize } from 'src/styles/utils/colors';
import { useMemo } from 'react';
import { useRouteResolver } from 'src/hooks/use-route-resolver';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import camelCase from 'lodash/camelCase';
import styled from 'styled-components';
import svgButton from 'src/assets/svg/button.svg';

/**
 * `Props` type.
 */

type Props = {
  allSearchResults: AllSearchResults;
  currentSearch: string;
};

/**
 * `HitProps` type.
 */

type HitProps = {
  hit: { [key: string]: any };
  indexName: keyof typeof algoliaRoutesNames;
};

/**
 * `HitsProps` type.
 */

type HitsProps = {
  hasMore: boolean;
  hits: SearchHits;
  indexName: keyof typeof algoliaRoutesNames;
  refineNext: () => void;
};

/**
 * `IndexTitle` styled component.
 */

const IndexTitle = styled(Text).attrs({ as: 'div', variant: 'h3' })`
  color: var(--color-white);

  sup {
    ${textStyles.subtitle2}

    left: 8px;
  }
`;

/**
 * `HitsWrapper` styled component.
 */

const HitsWrapper = styled.div`
  margin-bottom: var(--vertical-spacing);
`;

/**
 * `HitsList` styled component.
 */

const HitsList = styled.ol<{ hasMore: boolean }>`
  list-style-type: none;
  padding: 40px 0 ${ifProp('hasMore', '40px', 0)};
`;

/**
 * `HitsListItem` styled component.
 */

const HitsListItem = styled.li`
  border-top: 1px solid ${transparentize('white', 0.2)};

  :last-child {
    border-bottom: 1px solid ${transparentize('white', 0.2)};
  }
`;

/**
 * `HitsListItemLink` styled component.
 */

const HitsListItemLink = styled(RouterLink)`
  align-items: center;
  background-color: transparent;
  color: var(--color-white);
  column-gap: 4px;
  display: flex;
  padding: 16px 8px;
  transition: background-color var(--transition-default);

  :hover,
  :focus {
    background-color: ${transparentize('white', 0.2)};
    outline: none;
  }
`;

/**
 * `EmptyTitle` styled component.
 */

const EmptyTitle = styled(Text).attrs({ as: 'p', variant: 'h3' })`
  color: var(--color-white);
  margin-bottom: 48px;
`;

/**
 * `Hit` component.
 */

const Hit = ({ hit, indexName }: HitProps) => {
  const routeResolver = useRouteResolver();
  const breadcrumb = useBreadcrumb(hit);

  return (
    <HitsListItemLink
      href={routeResolver(algoliaRoutesNames[indexName], {
        slug: hit?.slug
      })}
    >
      <Svg
        icon={svgButton}
        size={'16px'}
      />

      <span>
        <HitBreadcrumb isLight>{breadcrumb}</HitBreadcrumb>

        {hit?.title ?? hit?.name}
      </span>
    </HitsListItemLink>
  );
};

/**
 * `Hits` component.
 */

const Hits = connectInfiniteHits((props: HitsProps) => {
  const { hasMore, hits, indexName, refineNext } = props;
  const { t } = useTranslation();

  return (
    <HitsWrapper>
      <HitsList hasMore={hasMore}>
        {hits.map((hit, index) => (
          <HitsListItem key={index}>
            <Hit
              hit={hit}
              indexName={indexName}
            />
          </HitsListItem>
        ))}
      </HitsList>

      {hasMore && (
        <Button
          colorTheme={'whiteOutlined'}
          icon={svgButton}
          onClick={refineNext}
          size={'small'}
        >
          {t('common:actions.loadMore')}
        </Button>
      )}
    </HitsWrapper>
  );
});

/**
 * `getIndexTotal`.
 */

function getIndexTotal(allSearchResults: AllSearchResults, index: string) {
  return allSearchResults?.[index]?.nbHits;
}

/**
 * Export `SearchResults` component.
 */

// @ts-ignore
export const SearchResults = connectStateResults((props: Props) => {
  const { allSearchResults, currentSearch } = props;
  const { locale } = useRouter();
  const { t } = useTranslation();
  const routeResolver = useRouteResolver();
  const total = useMemo(() => {
    return algoliaIndexesOrder?.reduce((result, index) => {
      return (
        result + getIndexTotal(allSearchResults, `${index}_${locale as string}`)
      );
    }, 0);
  }, [allSearchResults, locale]);

  return (
    <>
      {total === 0 ? (
        <>
          <EmptyTitle>
            {t('common:navbar.search.emptyResults', { value: currentSearch })}
          </EmptyTitle>

          <Button
            colorTheme={'white'}
            href={routeResolver('home')}
            icon={svgButton}
          >
            {t('common:navbar.search.homeButton')}
          </Button>
        </>
      ) : (
        algoliaIndexesOrder.map(index => {
          const indexName = `${index}_${locale}`;
          const indexTotal = getIndexTotal(allSearchResults, indexName);

          return indexTotal === 0 ? null : (
            <Index
              indexName={indexName}
              key={indexName}
            >
              <IndexTitle>
                {t(`common:navbar.search.indexes.${camelCase(index)}`)}

                {indexTotal > 1 && <sup>{indexTotal}</sup>}
              </IndexTitle>

              <Configure hitsPerPage={algoliaPaginationLimit} />

              <Hits indexName={index} />
            </Index>
          );
        })
      )}
    </>
  );
});
