/**
 * Module dependencies.
 */

import { ElementType } from 'react';
import { setLinkProps } from 'src/core/utils/links';
import styled from 'styled-components';

/**
 * `Props` interface.
 */

type Props = {
  as?: ElementType;
  className?: string;
  href?: string;
  onClick?: () => void;
};

/**
 * `Link` styled component.
 */

export const Link = styled.a.attrs<Props>(setLinkProps)`
  appearance: none;
  border-bottom: 2px solid transparent;
  transition: border-color var(--transition-default);

  :hover,
  :focus {
    border-color: currentColor;
    outline: none;
  }
`;
