/**
 * Module dependencies.
 */

import { colors } from './colors';
import { css } from 'styled-components';
import { gradients } from './gradients';
import { media } from './utils/media';
import { shadows } from './shadows';

/**
 * Export `containerMaxWidth`.
 */

export const containerMaxWidth = '1556px';

/**
 * Create css variables.
 */

function createCssVariables(list: { [key: string]: string }, suffix: string) {
  return Object.entries(list).reduce((previous: string, [name, value]) => {
    return `${previous} --${suffix}-${name}: ${value};`;
  }, '');
}

/**
 * Export `rootVariables`.
 */

export const rootVariables = css`
  :root {
    ${createCssVariables(colors, 'color')}
    ${createCssVariables(shadows, 'shadow')}
    ${createCssVariables(gradients, 'gradient')}

    /**
     * Font family.
     */

    --font-family-playfair: 'Playfair Display', serif;
    --font-family-poppins: 'Poppins', sans-serif;

    /**
     * Container.
     */

    --container-max-width: ${containerMaxWidth};

    /**
     * Grid.
     */

    --gutter: calc(100vw / 12);

    /**
     * Navbar.
     */

    --navbar-gutter: 20px;
    --navbar-height: 104px;

    ${media.min.sm`
      --navbar-gutter: 40px;
      --navbar-height: 144px;
    `}

    /**
     * Scrollbar.
     */

    --scrollbar-background: var(--color-gray200);
    --scrollbar-border-radius: 8px;
    --scrollbar-thumb-background: var(--color-gray400);
    --scrollbar-width: 16px;
    --scrollbar-thumb-width: 8px;

    /**
     * Transitions.
     */

    --transition-animation: cubic-bezier(0.4, 0, 0.2, 1);
    --transition-default: 350ms var(--transition-animation);
    --transition-fast: 150ms var(--transition-animation);
    --transition-slow: 500ms ease-in-out;

    /**
     * Vertical spacing.
     */

    --vertical-spacing: min(8rem, 10vh);

    ${media.min.lg`--vertical-spacing: min(8rem, 14vh);`}

    /**
     * Z-indexes.
     */

    --z-index-cookies-banner: 99;
    --z-index-loading: 100;
    --z-index-menu: 97;
    --z-index-modal-underlay: 98;
    --z-index-navbar: 98;
    --z-index-search: 97;
  }
`;
