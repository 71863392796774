/**
 * Export `serviceAreaEnum` enum.
 */

export const serviceAreaEnum = {
  body: 'body',
  breast: 'breast',
  face: 'face',
  genital: 'genital',
  hair: 'hair',
  mind: 'mind',
  oculoplastic: 'oculoplastic'
} as const;

/**
 * Export `serviceCategoryEnum` enum.
 */

export const serviceCategoryEnum = {
  area: 'area',
  gender: 'gender',
  type: 'type'
} as const;

/**
 * Export `serviceGenderEnum` enum.
 */

export const serviceGenderEnum = {
  man: 'man',
  woman: 'woman'
} as const;

/**
 * Export `serviceTypeEnum` enum.
 */

export const serviceTypeEnum = {
  aesthetic: 'aesthetic',
  healthWellness: 'health_wellness',
  mindfulness: 'mindfulness'
} as const;

/**
 * Export `categoryMagazineEnum` enum.
 */

export const categoryMagazineEnum = {
  aesthetic: 'aesthetic',
  healthWellness: 'health_wellness',
  mindfulness: 'mindfulness',
  other: 'other'
} as const;

/**
 * Export `serviceSubcategoryEnum` enum.
 */

export const serviceSubcategoryEnum = {
  ...serviceGenderEnum,
  ...serviceTypeEnum,
  ...serviceAreaEnum
} as const;

/**
 * Export `orderedServiceAreaEnum` enum.
 */

export const orderedServiceAreaEnum = [
  serviceAreaEnum.face,
  serviceAreaEnum.body,
  serviceAreaEnum.oculoplastic,
  serviceAreaEnum.hair,
  serviceAreaEnum.breast,
  serviceAreaEnum.genital,
  serviceAreaEnum.mind
] as const;

/**
 * Export `orderedServiceTypeEnum` enum.
 */

export const orderedServiceTypeEnum = [
  serviceTypeEnum.aesthetic,
  serviceTypeEnum.healthWellness,
  serviceTypeEnum.mindfulness
] as const;

/**
 * Export `orderedServiceGenderEnum` enum.
 */

export const orderedServiceGenderEnum = [
  serviceGenderEnum.woman,
  serviceGenderEnum.man
] as const;

/**
 * Export `allServicesOrder` enum.
 */

export const allServicesOrder = [
  serviceTypeEnum.aesthetic,
  serviceAreaEnum.body,
  serviceAreaEnum.face,
  serviceAreaEnum.hair,
  serviceAreaEnum.genital,
  serviceTypeEnum.healthWellness
] as const;
