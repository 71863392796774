/**
 * Module dependencies.
 */

import { InputTheme } from 'src/components/core/forms/styles';
import { ReactElement, ReactNode, forwardRef } from 'react';
import { Size } from 'src/types/styles';
import { ifProp, switchProp } from 'styled-tools';
import { svgBackground } from '@untile/react-components/dist/styles';
import { textStyles } from 'src/components/core/text';
import check from 'src/assets/svg/check.svg';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * Export `CheckboxProps` type.
 */

export type CheckboxProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  'type' | 'as' | 'label' | 'size'
> & {
  className?: string;
  disabled?: boolean;
  error?: string;
  label: ReactNode;
  size?: Size;
  theme?: InputTheme;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.label<Pick<CheckboxProps, 'theme'>>`
  display: inline-flex;
  place-items: center;
  position: relative;

  &:hover,
  &:focus {
    outline: none;
  }

  ${switchProp('theme', {
    dark: `
      --checkbox-border-color: var(--color-gray500);
      --checkbox-label-color: var(--color-gray700);
    `,
    light: `
      --checkbox-border-color: var(--color-white);
      --checkbox-label-color: var(--color-white);
    `
  })}
`;

/**
 * `Label` styled component.
 */

const Label = styled.span<{ size: Size }>`
  color: var(--checkbox-label-color);
  padding-left: 32px;
  transition: color var(--transition-default);

  ${switchProp('size', {
    medium: `
      ${textStyles.paragraph}
    `,
    small: `
      ${textStyles.small}
    `
  })}

  &::before {
    background-position: -4px -4px;
    background-size: 24px 24px;
    border: 2px solid var(--checkbox-border-color);
    border-radius: 4px;
    content: '';
    display: inline-block;
    height: 20px;
    left: 0;
    margin-right: 12px;
    outline-color: transparent;
    outline-offset: 1px;
    outline-style: solid;
    outline-width: 2px;
    position: absolute;
    top: 6px;
    transition: 0.4s linear;
    transition-property: outline, border-color, background-color;
    width: 20px;
  }
`;

/**
 * `Input` styled component.
 */

const Input = styled.input.attrs({
  type: 'checkbox'
})<{ hasError: boolean }>`
  cursor: pointer;
  height: 0;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 0;
  z-index: 1;

  &:checked + ${Label}::before {
    ${svgBackground(check.replace('currentColor', 'white'))}

    background-color: var(--color-sage600);
    border-color: var(--color-sage600);
  }

  &:not([disabled]) {
    &:hover,
    &:focus {
      & + ${Label}::before {
        outline-color: var(--color-sage700);
        outline-offset: 1px;
        outline-style: solid;
        outline-width: 2px;
      }
    }
  }

  &:checked:hover,
  &:checked:focus {
    & + ${Label}::before {
      background-color: var(--color-sage700);
      border-color: var(--color-sage700);
    }
  }

  ${ifProp(
    'disabled',
    `
    & + ${Label} {
      color: var(--color-gray300);

      &::before {
        border-color: var(--color-gray300);
      }
    }
  }
  `
  )}

  ${ifProp(
    'hasError',
    `
    & + ${Label} {
      color: var(--color-red500);

      &::before {
        border-color: var(--color-red500);
      }
    }

    &:hover,
    &:focus {
      & + ${Label}::before {
        border-color: var(--color-red600);
        outline-color: var(--color-red600) !important;
      }
    }
  `
  )}
`;

/**
 * Export `Checkbox` component.
 */

export const Checkbox = forwardRef(
  (props: CheckboxProps, forwardedRef: any): ReactElement => {
    const {
      className,
      disabled,
      error,
      label,
      size = 'medium',
      theme = 'dark',
      value,
      ...rest
    } = props;

    return (
      <Wrapper
        className={className}
        theme={theme}
      >
        <Input
          {...rest}
          checked={value as any}
          disabled={disabled}
          hasError={!isEmpty(error)}
          ref={forwardedRef}
        />

        <Label size={size as any}>{label}</Label>
      </Wrapper>
    );
  }
);

/**
 * `Checkbox` display name.
 */

Checkbox.displayName = 'Checkbox';
