/**
 * Module dependencies.
 */

import { ContactButtons } from './contact-buttons';
import { Link } from 'src/components/core/links/link';
import { Locale } from 'i18n-routes';
import { MenuItem } from './menu-item';
import { TransitionWrapper } from './transition-wrapper';
import { footerMenuItems, menuItems } from 'src/core/constants/menu';
import { getRouteByPathname } from 'src/core/utils/routes';
import { ifProp } from 'styled-tools';
import { locales } from 'src/core/constants/locales';
import { media } from 'src/styles/utils/media';
import { textStyles } from 'src/components/core/text';
import { useBreakpoint } from '@untile/react-components';
import { useRouteResolver } from 'src/hooks/use-route-resolver';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import styled from 'styled-components';

/**
 * Constants.
 */

const currentYear = new Date().getFullYear();

/**
 * `Props` type.
 */

type Props = {
  isOpen: boolean;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ isVisible: boolean }>`
  inset: 0;
  padding: calc(var(--navbar-height) + 32px) 0 24px;
  pointer-events: none;
  position: fixed;
  visibility: hidden;
  z-index: var(--z-index-menu);

  ::before {
    background: var(--gradient-menu);
    clip-path: circle(30px at calc(100% - 48px) 52px);
    content: '';
    display: block;
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    transition: all var(--transition-slow);
    visibility: hidden;
    width: 100vw;
    z-index: -1;
  }

  ul {
    line-height: 0;
  }

  ${media.min.sm`
    ::before {
      clip-path: circle(30px at calc(100% - 68px) 72px);
    }
  `}

  ${ifProp(
    'isVisible',
    `
      pointer-events: all;
      visibility: visible;

      ::before {
        clip-path: circle(100%);
        visibility: visible;
      }
    `
  )}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled(TransitionWrapper)`
  color: var(--color-white);
  display: grid;
  grid-row-gap: 16px;
  grid-template-areas:
    'content    content'
    'legalLinks legalLinks'
    'copyright  languages';
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: 1fr repeat(2, max-content);
  height: 100%;
  overflow-y: auto;
  padding: 0 var(--gutter);

  ${media.min.md`
    grid-template-areas:
      '.         content    content'
      'copyright legalLinks languages';
    grid-template-columns: 3fr 7fr 2fr;
    grid-template-rows: 1fr minmax(50px, max-content);
    padding: 0;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  grid-area: content;
`;

/**
 * `Actions` styled component.
 */

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;

  > * {
    margin-bottom: 24px;
    width: max-content;
  }
`;

/**
 * `Copyright` styled component.
 */

const Copyright = styled.span`
  ${textStyles.small}

  align-self: center;
  grid-area: copyright;

  ${media.min.sm`
    ${textStyles.paragraph}
  `}

  ${media.min.md`
    padding-left: var(--navbar-gutter);
  `}
`;

/**
 * `Languages` styled component.
 */

const Languages = styled.ul`
  align-self: center;
  column-gap: 8px;
  display: flex;
  grid-area: languages;
  justify-self: end;

  ${media.min.md`
    padding-right: var(--navbar-gutter);
  `}
`;

/**
 * `LegalLinks` styled component.
 */

const LegalLinks = styled.ul`
  align-self: center;
  column-gap: 8px;
  display: flex;
  grid-area: legalLinks;

  > li:not(:last-child)::after {
    content: '|';
    margin-left: 8px;
  }
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)<{ active?: string }>`
  ${textStyles.small}
  ${media.min.sm`
    ${textStyles.paragraph}
  `}

  ${ifProp('active', 'border-color: currentColor;')}
`;

/**
 * `MenuLinks` styled component.
 */

const MenuLinks = styled.ul`
  > li:not(:last-child) {
    margin-bottom: 16px;
  }
`;

/**
 * Export `Menu` component.
 */

export function Menu({ isOpen }: Props) {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const isDesktop = useBreakpoint('lg');
  const resolveRoute = useRouteResolver();
  const router = useRouter();
  const currentRoute = getRouteByPathname(router?.pathname);

  return (
    <Wrapper isVisible={isOpen}>
      <Grid isOpen={isOpen}>
        <Content>
          <MenuLinks>
            {menuItems(locale as Locale).map(
              ({ routeName, submenu, ...rest }, index) => (
                <li key={index}>
                  <MenuItem
                    {...rest}
                    active={(currentRoute && routeName === currentRoute) ||
                      (currentRoute &&
                        some(submenu, { routeName: currentRoute }))}
                    routeName={routeName}
                    submenu={submenu}
                  />
                </li>
              )
            )}
          </MenuLinks>

          {!isDesktop && (
            <Actions>
              <ContactButtons theme={'white'} />
            </Actions>
          )}
        </Content>

        <Copyright>{`Ousia © ${currentYear}`}</Copyright>

        <LegalLinks>
          {footerMenuItems.map(({ href, label }, index) => (
            <li key={index}>
              <StyledLink
                href={href}
                target={'_blank'}
              >
                {t(label)}
              </StyledLink>
            </li>
          ))}
        </LegalLinks>

        {!isEmpty(locales) && locales?.length > 1 && (
          <Languages>
            {locales.map(lang => (
              <li key={lang}>
                <StyledLink
                  active={lang === locale ? 'true' : undefined}
                  href={resolveRoute('home', { locale: lang })}
                  // @ts-ignore
                  locale={false}
                >
                  {lang.toUpperCase()}
                </StyledLink>
              </li>
            ))}
          </Languages>
        )}
      </Grid>
    </Wrapper>
  );
}
