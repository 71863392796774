/**
 * Module dependencies.
 */

import { Fragment, useCallback, useState } from 'react';
import { HitBreadcrumb, useBreadcrumb } from './hit-breadcrumb';
import { Input } from 'src/components/core/forms/input';
import { Message } from 'src/components/core/forms/styles';
import { connectAutoComplete, connectHighlight } from 'react-instantsearch-dom';
import { transparentize } from 'src/styles/utils/colors';
import { useTranslation } from 'next-i18next';
import Autosuggest, {
  RenderSuggestionsContainerParams
} from 'react-autosuggest';

import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import svgArrowRight from 'src/assets/svg/arrow-right.svg';

/**
 * `Props` type.
 */

type Props = {
  currentRefinement: string;
  currentSearch: string;
  hits: any;
  onSuggestionClear: () => void;
  onSuggestionSelected: (value: string) => void;
  refine: (value?: string) => void;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: var(--vertical-spacing);
  position: relative;

  small {
    color: var(--color-white);
  }
`;

/**
 * `Dropdown` styled component.
 */

const Dropdown = styled.div`
  background-color: var(--color-white);
  border-radius: 4px;
  box-shadow: 0 24px 40px -16px ${transparentize('black', 0.1)};
  left: 0;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 8px);
  width: 100%;

  ul > li {
    background-color: transparent;
    color: var(--color-gray700);
    cursor: pointer;
    padding: 12px 16px;
    transition: background-color var(--transition-default);

    :not(:last-child) {
      border-bottom: 1px solid var(--color-gray300);
    }

    :hover,
    :focus {
      background-color: var(--color-cappuccino300);
    }

    :active {
      background-color: var(--color-cappuccino400);
    }
  }
`;

/**
 * `Highlight` component.
 */

const Highlight = connectHighlight(({ attribute, highlight, hit }) => {
  const parsedHit = highlight({
    attribute,
    highlightProperty: '_highlightResult',
    hit
  });

  if (isEmpty(parsedHit)) {
    return null;
  }

  const breadcrumb = useBreadcrumb(hit);

  return (
    <>
      <HitBreadcrumb>{breadcrumb}</HitBreadcrumb>

      {parsedHit.map((part, index: number) => (
        <Fragment key={index}>{part.value}</Fragment>
      ))}
    </>
  );
});

/**
 * `SuggestionsContainer` component.
 */

const SuggestionsContainer = (props: RenderSuggestionsContainerParams) => {
  const { children, containerProps } = props;

  return <Dropdown {...containerProps}>{children}</Dropdown>;
};

/**
 * `Suggestion` component.
 */

const Suggestion = ({ suggestion }: any) => (
  <Highlight
    attribute={Object.keys(suggestion).includes('name') ? 'name' : 'title'}
    hit={suggestion}
  />
);

/**
 * Export `SearchForm` component.
 */

export const SearchForm = connectAutoComplete((props: Props) => {
  const {
    currentRefinement,
    currentSearch,
    hits,
    onSuggestionClear,
    onSuggestionSelected,
    refine
  } = props;

  const { t } = useTranslation();
  const [value, setValue] = useState(currentRefinement);
  const handleChange = useCallback(
    (event: any, { newValue }: any) => {
      if (!newValue) {
        onSuggestionClear();
      }

      setValue(newValue);
      onSuggestionSelected(newValue);
    },
    [onSuggestionClear, onSuggestionSelected]
  );

  return (
    <Wrapper>
      <div style={{ position: 'relative', zIndex: 1 }}>
        <Autosuggest
          alwaysRenderSuggestions={false}
          getSectionSuggestions={section => section.hits}
          getSuggestionValue={suggestion =>
            suggestion?.title || suggestion?.name}
          inputProps={{
            name: 'searchInput',
            onChange: handleChange,
            placeholder: t('common:navbar.search.input.placeholder'),
            value
          }}
          multiSection
          onSuggestionsClearRequested={refine}
          onSuggestionsFetchRequested={({ value }) => refine(value as string)}
          renderInputComponent={inputProps => (
            // @ts-ignore
            <Input
              {...inputProps}
              icon={svgArrowRight}
              theme={'light'}
            />
          )}
          renderSectionTitle={() => null}
          renderSuggestion={suggestion => (
            <Suggestion suggestion={suggestion} />
          )}
          renderSuggestionsContainer={SuggestionsContainer}
          suggestions={hits}
        />
      </div>

      {isEmpty(currentSearch) && (
        <Message type={'info'}>
          {t('common:navbar.search.input.helpText')}
        </Message>
      )}
    </Wrapper>
  );
});
