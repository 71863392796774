/**
 * Module dependencies.
 */

import { ButtonProps, buttonSizes, buttonThemes } from './button';
import { Svg } from 'src/components/core/svg';
import { forwardRef } from 'react';
import { ifProp, switchProp } from 'styled-tools';
import { setButtonLinkProps } from 'src/core/utils/links';
import styled from 'styled-components';

/**
 * Export `RoundButtonProps` type.
 */

export type RoundButtonProps = Omit<
  ButtonProps,
  'children' | 'hasIcon' | 'isLoading' | 'stretch'
> & {
  icon: string;
};

/**
 * `Element` styled component.
 */

const Element = styled.button.attrs(setButtonLinkProps)<RoundButtonProps>`
  ${switchProp('colorTheme', buttonThemes)}
  ${switchProp('size', buttonSizes)}

  -webkit-tap-highlight-color: transparent;
  background-color: var(--button-background-color);
  border: 1px solid var(--button-border-color);
  border-radius: var(--button-border-radius);
  color: var(--button-text-color);
  cursor: pointer;
  display: inline-flex;
  height: var(--button-height);
  justify-content: center;
  outline: none;
  padding: 0;
  place-items: center;
  position: relative;
  transition: var(--transition-default);
  transition-property: background-color, border-color, color, opacity;
  white-space: nowrap;
  width: var(--button-height);

  &:focus,
  &:hover {
    background-color: var(--button-active-background-color);
    border-color: var(--button-active-border-color);
    color: var(--button-active-text-color);
  }

  ${ifProp(
    'disabled',
    `
    background-color: var(--button-disabled-background-color);
    border-color: var(--button-disabled-border-color);
    color: var(--button-disabled-text-color);
    cursor: default;
    pointer-events: none;
  `
  )}
`;

/**
 * Export `RoundButton` component.
 */

export const RoundButton = forwardRef<any, RoundButtonProps>(
  (props: RoundButtonProps, ref: any) => {
    const { colorTheme = 'green', icon, size = 'medium', ...rest } = props;

    return (
      <Element
        {...rest}
        colorTheme={colorTheme}
        ref={ref}
        size={size}
      >
        <Svg
          icon={icon}
          size={size === 'small' ? '24px' : '48px'}
        />
      </Element>
    );
  }
);

/**
 * `RoundButton` display name.
 */

RoundButton.displayName = 'RoundButton';
