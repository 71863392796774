/**
 * Module dependencies.
 */

import { keyframes } from 'styled-components';

/**
 * Export `animatedBlob`.
 */

export const animatedBlob = keyframes`
  0%, 100% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(0, 0) rotate(15deg) scale(1.1);
  }

  50% {
    transform: translate(10%, -40%) rotate(45deg);
  }

  70% {
    transform: translate(38%, -52%) rotate(128deg) scale(0.9);
  }
`;

/**
 * Export `animatedCircle`.
 */

export const animatedCircle = keyframes`
  0% {
    background-position: 0 0, 0 100%, 100% 100%, 100% 0;
  }

  25% {
    background-position: 0 100%, 100% 100%, 100% 0, 0 0;
  }

  50% {
    background-position: 100% 100%, 100% 0, 0 0, 0 100%;
  }

  75% {
    background-position: 100% 0, 0 0, 0 100%, 100% 100%;
  }

  100% {
    background-position: 0 0, 0 100%, 100% 100%, 100% 0;
  }
`;

/**
 * Export `clipFrom` keyframes.
 */

export const clipFrom = keyframes`
  from {
    clip-path: inset(var(--animation-clip-from-value));
  }

  to {
    clip-path: inset(0);
  }
`;

/**
 * Export `fadeFrom` keyframes.
 */

export const fadeFrom = keyframes`
  from {
    opacity: 0;
    transform: translate(var(--animation-fade-from-value));
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

/**
 * Export `fadeInUp` keyframes.
 */

export const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(max(20%, 80px));
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

/**
 * Export `fadeOutDown` keyframes.
 */

export const fadeOutDown = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(80px);
  }
`;
