/**
 * Module dependencies.
 */

import { Fill } from '@untile/react-components';
import { prop } from 'styled-tools';
import { transparentize } from 'src/styles/utils/colors';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import times from 'lodash/times';

/**
 * `Props` type.
 */

type Props = {
  columns: number;
  gap: number;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Fill)`
  margin: 0 auto;
  max-width: var(--container-max-width);
  pointer-events: none;
  position: fixed;
  z-index: 999;

  * {
    height: 100%;
  }
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div<Props>`
  display: grid;
  grid-gap: ${prop('gap', 0)}px;
  grid-template-columns: repeat(${prop('columns')}, 1fr);
`;

/**
 * `Column` styled component.
 */

const Column = styled.div`
  background-color: ${transparentize('black', 0.25)};
`;

/**
 * Export `GridDebug` component.
 */

export const GridDebug = (props: Props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.shiftKey && event.code === 'KeyG') {
        setVisible(!visible);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [visible]);

  return !visible ? null : (
    <Wrapper>
      <Grid {...props}>
        {times(props.columns, index => (
          <Column key={index} />
        ))}
      </Grid>
    </Wrapper>
  );
};
