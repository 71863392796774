/**
 * Module dependencies.
 */

import {
  Index,
  InstantSearch,
  connectSearchBox
} from 'react-instantsearch-dom';

import { RoundButton } from 'src/components/core/buttons/round-button';
import { SearchForm } from './search-form';
import { SearchResults } from './search-results';
import { TransitionWrapper } from 'src/components/layout/navbar/transition-wrapper';
import {
  algoliaClient,
  algoliaIndexesOrder,
  algoliaMainIndex
} from 'src/core/constants/algolia';

import { ifProp } from 'styled-tools';
import { media } from 'src/styles/utils/media';
import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import svgClose from 'src/assets/svg/close.svg';

/**
 * `Props` type.
 */

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled(RoundButton)`
  --button-background-color: transparent;
  --button-active-background-color: transparent;
  --button-disabled-background-color: transparent;

  border: none !important;
  position: absolute;
  right: var(--navbar-gutter);
  top: 32px;

  ${media.min.sm`
    top: 44px;
  `}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ isVisible: boolean }>`
  inset: 0;
  padding: calc(var(--navbar-height) + 32px) 0 24px;
  pointer-events: none;
  position: fixed;
  z-index: var(--z-index-search);

  ::before {
    background: var(--gradient-menu);
    clip-path: circle(5px at calc(100% - 136px) 52px);
    content: '';
    inset: 0;
    position: absolute;
    transition: all var(--transition-slow);
    visibility: hidden;
    z-index: -1;
  }

  ${media.min.sm`
    ::before {
      clip-path: circle(5px at calc(100% - 156px) 72px);
    }
  `}

  ${ifProp(
    'isVisible',
    css`
      pointer-events: all;

      ::before {
        clip-path: circle(100%);
        visibility: visible;
      }
    `
  )}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled(TransitionWrapper)`
  display: grid;
  grid-template-areas: 'inputWrapper' 'resultsWrapper';
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  height: 100%;
  padding: 0 var(--navbar-gutter);

  ${media.min.ms`
    grid-template-areas:
    '. inputWrapper   .'
    '. resultsWrapper .';
    grid-template-columns: 1fr 4fr 1fr;
  `}

  ${media.min.lg`
    grid-template-columns: 3fr 6fr 3fr;
  `}
`;

/**
 * `VirtalSearchBox` component.
 */

const VirtalSearchBox = connectSearchBox(() => null);

/**
 * Export `Search` component.
 */

export const Search = ({ isOpen, onClose }: Props) => {
  const { locale } = useRouter();
  const [query, setQuery] = useState<string>('');
  const handleClose = useCallback(() => {
    onClose();
    setQuery('');
  }, [onClose]);

  return (
    <Wrapper isVisible={isOpen}>
      {isOpen && (
        <CloseButton
          colorTheme={'whiteOutlined'}
          icon={svgClose}
          onClick={handleClose}
        />
      )}

      <Grid isOpen={isOpen}>
        <div style={{ gridArea: 'inputWrapper' }}>
          <InstantSearch
            indexName={`${algoliaMainIndex}_${locale}`}
            searchClient={algoliaClient}
          >
            <SearchForm
              currentSearch={query}
              onSuggestionClear={() => setQuery('')}
              onSuggestionSelected={(value: string) => setQuery(value)}
            />

            {algoliaIndexesOrder.map(indexName => (
              <Index
                indexName={`${indexName}_${locale}`}
                key={indexName}
              />
            ))}
          </InstantSearch>
        </div>

        <div style={{ gridArea: 'resultsWrapper', overflowY: 'auto' }}>
          <InstantSearch
            indexName={`${algoliaMainIndex}_${locale}`}
            searchClient={algoliaClient}
          >
            <VirtalSearchBox defaultRefinement={query} />

            {algoliaIndexesOrder.map(indexName => (
              <Index
                indexName={`${indexName}_${locale}`}
                key={indexName}
              />
            ))}

            <SearchResults
              // @ts-ignore
              currentSearch={query}
            />
          </InstantSearch>
        </div>
      </Grid>
    </Wrapper>
  );
};
