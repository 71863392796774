/**
 * Module dependencies.
 */

import { Size } from 'src/types/styles';
import { Svg, SvgProps } from 'src/components/core/svg';
import { ifProp, switchProp } from 'styled-tools';
import { isValidElement } from 'react';
import { textStyles } from 'src/components/core/text';
import styled from 'styled-components';

/**
 * `inputThemes` constant.
 */

export const inputThemes = {
  dark: `
    --input-active-text-color: var(--color-gray800);
    --input-border-bottom-color: var(--color-sage700);
    --input-error-text-color: var(--color-red500);
    --input-info-text-color: var(--color-gray600);
    --input-label-color: var(--color-sage700);
    --input-placeholder-color: var(--color-gray600);
    --input-text-color: var(--color-gray700);
  `,
  light: `
    --input-active-text-color: var(--color-white);
    --input-border-bottom-color: var(--color-white);
    --input-error-text-color: var(--color-red500);
    --input-info-text-color: var(--color-gray200);
    --input-label-color: var(--color-white);
    --input-placeholder-color: var(--color-white);
    --input-text-color: var(--color-white);
  `
};

/**
 * `InputTheme` type.
 */

export type InputTheme = keyof typeof inputThemes;

/**
 * Export `FormField` styled component.
 */

export const FormField = styled.div<{
  disabled?: boolean | undefined;
  hasError?: boolean;
  theme: InputTheme;
}>`
  align-items: center;
  display: grid;
  grid-template-areas: 'label' 'inputArea' 'message';
  margin-bottom: 24px;
  position: relative;

  :first-child,
  & + &:not(:last-child) {
    margin-bottom: 0;
  }

  ${switchProp('theme', inputThemes, inputThemes.light)}

  ${ifProp(
    'disabled',
    `
    opacity: 0.75;
    pointer-events: none;
  `
  )}

  ${ifProp(
    'hasError',
    `
    --input-border-bottom-color: var(--color-red500);
    --input-label-color: var(--color-red500);
  `
  )}
`;

/**
 * Export `Field` styled component.
 */

export const Field = styled.div<{ hasIcon?: boolean }>`
  border-bottom: 1px solid var(--input-border-bottom-color);
  color: var(--input-text-color);
  display: grid;
  grid-area: inputArea;
  grid-template-areas: 'input ${ifProp('hasIcon', 'icon', 'input')}';
  grid-template-columns: auto min-content;
  opacity: 0.4;
  transition: var(--transition-default);
  transition-property: border-color, opacity;

  &[data-has-value='true'],
  &:focus-within {
    opacity: 1;
  }

  &:focus-within {
    --input-text-color: var(--input-active-text-color);
  }
`;

/**
 * Export `Label` styled component.
 */

export const Label = styled.label<{
  isRequired?: boolean;
  labelSize?: Size;
}>`
  align-items: center;
  color: var(--input-label-color);
  transition: color var(--transition-default);

  ${switchProp(
    'labelSize',
    {
      medium: `
      ${textStyles.paragraph}
    `,
      small: `
      ${textStyles.small}
    `
    },
    textStyles.paragraph
  )}

  ${ifProp(
    'isRequired',
    `
    &::after {
      content: '*';
    }
  `
  )}
`;

/**
 * Export `Message` styled component.
 */

export const Message = styled.small<{ type: 'error' | 'info' }>`
  ${textStyles.small}

  align-items: center;
  color: ${switchProp('type', {
    error: 'var(--input-error-text-color)',
    info: 'color: var(--input-info-text-color)'
  })};
  display: flex;
  grid-area: message;
  padding-top: 4px;
`;

/**
 * `TransparentButton` styled component.
 */

const TransparentButton = styled.button<{ disabled?: boolean }>`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  color: var(--input-label-color);
  grid-area: icon;
  height: 48px;
  justify-content: center;
  margin: 0;
  padding: 0;
  transition: color var(--transition-default);
  width: 48px;

  ${ifProp('onClick', 'cursor: pointer;', 'pointer-events: none;')}

  &:focus,
  &:hover {
    color: var(--color-sage700);
    outline: none;
  }
`;

/**
 * `InputIconProps` type.
 */

type InputIconProps = Partial<SvgProps> & {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
};

/**
 *  Export `InputIcon` component.
 */

export function InputIcon(props: InputIconProps) {
  const { icon, onClick, type, ...rest } = props;

  if (!icon) {
    return null;
  }

  return (
    <TransparentButton
      {...rest}
      as={onClick || type ? 'button' : 'div'}
      onClick={onClick}
      type={type}
    >
      {isValidElement(icon) ? icon : (
        <Svg
          icon={icon}
          size={'48px'}
        />
      )}
    </TransparentButton>
  );
}
