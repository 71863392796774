/**
 * Module dependencies.
 */

import { transparentize } from './utils/colors';

/**
 * Export `shadows`.
 */

export const shadows = {
  big: `0 24px 80px -8px ${transparentize('black', 0.15)}`,
  blur: `
    -10vw 0px 9vw 0px var(--color-blue500),
    0px -10vw 9vw 0px var(--color-blue500),
    0px 10vw 9vw 0px var(--color-blue500),
    10vw 0px 9vw 0px var(--color-blue500);
  `,
  'extra-small': `0 4px 8px ${transparentize('black', 0.1)}`,
  medium: `0 24px 40px -16px ${transparentize('black', 0.1)}`,
  small: `0 8px 32px ${transparentize('black', 0.1)}`
};
