/**
 * Module dependencies.
 */

import { CheckboxField } from 'src/components/core/forms/checkbox/field';
import { FieldsGrid } from './shared/styles';
import { InputField } from 'src/components/core/forms/input/field';
import { Link } from 'src/components/core/links/link';
import { NewsletterFormData } from 'src/requests/newsletter';
import { RecaptchaField } from 'src/components/core/forms/recaptcha';
import { Trans, useTranslation } from 'next-i18next';
import { emailRules, requiredRule } from 'src/core/forms/rules';
import { ifProp } from 'styled-tools';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import arrowRightSvg from 'src/assets/svg/arrow-right.svg';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  onSubmit: (data: NewsletterFormData, reset: () => void) => Promise<any>;
};

/**
 * `privacyLink` component.
 */

const privacyLink = [
  <Link
    href={process.env.NEXT_PUBLIC_PRIVATE_POLICY_URL}
    key={'privacyLink'}
    style={{ fontWeight: 'bold' }}
    target={'_blank'}
  />
];

/**
 * `StyledCheckboxField` styled component.
 */

const StyledCheckboxField = styled(CheckboxField)<{ isVisible: boolean }>`
  opacity: ${ifProp('isVisible', 1, 0)};
  transform: translateY(${ifProp('isVisible', 0, 20)}px);
  transition: var(--transition-default);
  transition-property: opacity, transform;
`;

/**
 * Export `NewsletterForm` component.
 */

export function NewsletterForm({ onSubmit, ...rest }: Props) {
  const { t } = useTranslation('common');
  const { control, formState, handleSubmit, reset, watch } =
    useForm<NewsletterFormData>({
      mode: 'onSubmit'
    });

  const emailValue = watch('email');
  const handleOnSubmit = useCallback(
    async (data: NewsletterFormData) => {
      onSubmit(data, reset);
    },
    [onSubmit, reset]
  );

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <FieldsGrid {...rest}>
        <InputField
          control={control}
          disabled={formState.isSubmitting}
          icon={arrowRightSvg}
          iconLabel={t('common:a11y.subscribeNewsletter')}
          iconType={'submit'}
          isLoading={formState.isSubmitting}
          name={'email'}
          placeholder={t('footer.newsletter.email')}
          rules={emailRules(t)}
          theme={'light'}
        />

        <StyledCheckboxField
          control={control as any}
          isVisible={!isEmpty(emailValue)}
          label={(
            <Trans
              components={privacyLink}
              i18nKey={'footer.newsletter.accept'}
              transSupportBasicHtmlNodes
            />
          )}
          name={'terms'}
          rules={requiredRule(t)}
          theme={'light'}
        />

        <RecaptchaField
          action={'subscribe'}
          control={control as any}
        />
      </FieldsGrid>
    </form>
  );
}
