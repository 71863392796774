/**
 * Module dependencies.
 */

import { Locale, RouteName } from 'i18n-routes';
import type { Menu } from 'src/types/menu';
import { getLocalizedCategoryByServiceSlug } from 'src/core/utils/routes';
import { serviceCategoryEnum } from './enums';

/**
 * Export `footerMenuItems` constant.
 */

export const footerMenuItems = [
  {
    href: process.env.NEXT_PUBLIC_COOKIES_POLICY_URL as string,
    label: 'common:navbar.legalLinks.cookiesPolicy'
  },
  {
    href: process.env.NEXT_PUBLIC_COOKIES_POLICY_URL as string,
    label: 'common:navbar.legalLinks.cookiesPolicy'
  },
  {
    href: process.env.NEXT_PUBLIC_TERMS_AND_CONDITIONS_URL as string,
    label: 'common:navbar.legalLinks.termsAndConditions'
  }
];

/**
 * Export `menuItems` constant.
 */

export const menuItems = (locale: Locale): Menu[] => [
  {
    label: 'common:navbar.menu.home',
    routeName: 'home'
  },
  {
    label: 'common:navbar.menu.clinic',
    submenu: ['whoWeAre', 'whatWeDo', 'whereWeAre'].map(value => ({
      label: `common:navbar.menu.${value}`,
      routeName: value as RouteName
    }))
  },
  {
    label: 'common:navbar.menu.services',
    submenu: Object.entries(serviceCategoryEnum).map(([key, value]) => ({
      label: `common:service.category.${key}`,
      routeInterpolations: {
        slug: getLocalizedCategoryByServiceSlug(locale, value)
      },
      routeName: 'serviceType'
    }))
  },
  {
    label: 'common:navbar.menu.fitness',
    routeName: 'fitness'
  },
  {
    label: 'common:navbar.menu.team',
    routeName: 'team'
  },
  {
    label: 'common:navbar.menu.magazine',
    routeName: 'magazine'
  },
  {
    label: 'common:navbar.menu.contacts',
    routeName: 'contacts'
  }
];
