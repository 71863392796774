/**
 * Module dependencies.
 */

import { Locale } from 'i18n-routes';
import { NewsletterPopup } from 'src/types/newsletter-popup';
import { axiosInstance, getApiEndpoint } from 'src/core/requests';
import { camelCaseObjectKeys } from 'src/core/utils/objects';
import { getHeadersWithLocale } from 'src/core/utils/requests';

/**
 * Export `getNewsletterPopup` request.
 */

export async function getNewsletterPopup(
  locale: Locale
): Promise<NewsletterPopup> {
  const response = await axiosInstance.get(
    getApiEndpoint('newsletterPopup'),
    getHeadersWithLocale(locale)
  );

  return camelCaseObjectKeys(response?.data?.data);
}
