/**
 * Module dependencies.
 */

import { RouteName } from 'i18n-routes';
import algoliasearch from 'algoliasearch/lite';

/**
 * Export `algoliaIndex`.
 */

export const algoliaIndex = {
  magazine: 'magazine_posts',
  service: 'services',
  staff: 'staff_members'
} as const;

/**
 * Export `algoliaObjectId`.
 */

export const algoliaObjectId = {
  magazine: 'MagazinePost',
  service: 'Service',
  staff: 'StaffMember'
} as const;

/**
 * Export `ObjectIdType`
 */

export type ObjectIdType = keyof typeof algoliaObjectId;

/**
 * Export `algoliaMainIndex`.
 */

export const algoliaMainIndex = algoliaIndex.service;

/**
 * Export `algoliaIndexesOrder`.
 */

export const algoliaIndexesOrder = [
  algoliaIndex.service,
  algoliaIndex.staff,
  algoliaIndex.magazine
];

/**
 * Export `algoliaClient`.
 */

export const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
  process.env.NEXT_PUBLIC_ALGOLIA_SECRET as string
);

/**
 * Export `algoliaPaginationLimit`.
 */

export const algoliaPaginationLimit = 3;

/**
 * Export `algoliaRoutesNames`.
 */

export const algoliaRoutesNames = {
  magazine_posts: 'magazineDetails',
  services: 'service',
  staff_members: 'teamMember'
} as { [key: string]: RouteName };
