/**
 * Module dependencies.
 */

import { defaultLocale } from 'src/core/constants/locales';
import { endpoints } from 'src/core/constants/endpoints';
import { getHeadersWithLocale } from './utils/requests';
import { regexes } from 'src/core/constants/regexes';
import axios from 'axios';
import template from 'lodash/template';

/**
 * Export `Axios` instance.
 */

export const axiosInstance = axios.create({
  ...getHeadersWithLocale(defaultLocale),
  baseURL: process.env.NEXT_PUBLIC_AXIOS_BASE_URL,
  paramsSerializer: {
    indexes: null
  },
  withCredentials: false
});

/**
 * Export `getApiEndpoint`.
 */

export function getApiEndpoint(
  endpointName: keyof typeof endpoints,
  interpolations?: Record<string, string | number>
): string {
  const endpoint = endpoints[endpointName];

  if (interpolations) {
    return template(endpoint, {
      interpolate: regexes.interpolateUrl
    })(interpolations);
  }

  return process.env.NEXT_PUBLIC_AXIOS_BASE_URL + endpoint;
}
