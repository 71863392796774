/**
 * Module dependencies.
 */

import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ElementType,
  forwardRef
} from 'react';

import { Loading } from 'src/components/core/loading';
import { Svg } from 'src/components/core/svg';
import { ifProp, switchProp } from 'styled-tools';
import { setButtonLinkProps } from 'src/core/utils/links';
import styled, { css } from 'styled-components';

/**
 * Export `ButtonProps` type.
 */

export type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  as?: ElementType;
  colorTheme?: 'green' | 'greenOutlined' | 'white' | 'whiteOutlined';
  href?: string;
  icon?: string;
  isLoading?: boolean;
  rel?: string;
  size?: 'medium' | 'small';
  stretch?: boolean;
  target?: string;
};

/**
 * Export `buttonThemesConfig` constant.
 */

export const buttonThemesConfig = {
  green: {
    active: {
      backgroundColor: 'var(--color-sage700)',
      borderColor: 'var(--color-sage700)',
      textColor: 'var(--color-white)'
    },
    disabled: {
      backgroundColor: 'var(--color-cappuccino300)',
      borderColor: 'var(--color-cappuccino300)',
      textColor: 'var(--color-sage300)'
    },
    idle: {
      backgroundColor: 'var(--color-sage500)',
      borderColor: 'var(--color-sage500)',
      textColor: 'var(--color-white)'
    }
  },
  greenOutlined: {
    active: {
      backgroundColor: 'var(--color-sage700)',
      borderColor: 'var(--color-sage700)',
      textColor: 'white'
    },
    disabled: {
      backgroundColor: 'transparent',
      borderColor: 'var(--color-sage300)',
      textColor: 'var(--color-sage300)'
    },
    idle: {
      backgroundColor: 'transparent',
      borderColor: 'var(--color-sage500)',
      textColor: 'var(--color-sage500)'
    }
  },
  white: {
    active: {
      backgroundColor: 'var(--color-sage700)',
      borderColor: 'var(--color-sage700)',
      textColor: 'white'
    },
    disabled: {
      backgroundColor: 'var(--color-cappuccino300)',
      borderColor: 'var(--color-cappuccino300)',
      textColor: 'var(--color-sage300)'
    },
    idle: {
      backgroundColor: 'var(--color-white)',
      borderColor: 'var(--color-white)',
      textColor: 'var(--color-sage500)'
    }
  },
  whiteOutlined: {
    active: {
      backgroundColor: 'var(--color-sage700)',
      borderColor: 'var(--color-sage700)',
      textColor: 'white'
    },
    disabled: {
      backgroundColor: 'transparent',
      borderColor: 'var(--color-cappuccino300)',
      textColor: 'var(--color-cappuccino300)'
    },
    idle: {
      backgroundColor: 'transparent',
      borderColor: 'var(--color-white)',
      textColor: 'var(--color-white)'
    }
  }
};

/**
 * Export `buttonTheme´ constant.
 */

export const buttonThemes = Object.entries(buttonThemesConfig).reduce(
  (previous, [themeName, config]) => ({
    ...previous,
    [themeName]: `
      --button-background-color: ${config.idle.backgroundColor};
      --button-border-color: ${config.idle.borderColor};
      --button-text-color: ${config.idle.textColor};
      --button-active-background-color: ${config.active.backgroundColor};
      --button-active-border-color: ${config.active.borderColor};
      --button-active-text-color: ${config.active.textColor};
      --button-disabled-background-color: ${config.disabled.backgroundColor};
      --button-disabled-border-color: ${config.disabled.borderColor};
      --button-disabled-text-color: ${config.disabled.textColor};
    `
  }),
  {}
);

/**
 * Export `buttonSizes` constant.
 */

export const buttonSizes = {
  medium: css`
    --button-height: 56px;
    --button-border-radius: 29px;
    --button-font-size: 24px;
  `,
  small: css`
    --button-height: 40px;
    --button-border-radius: 20px;
    --button-font-size: 14px;
  `
};

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg)`
  margin-left: 10px;
`;

/**
 * `StyledLoading` styled component.
 */

const StyledLoading = styled(Loading).attrs({
  relative: true,
  size: 16
})`
  margin-right: 10px;
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.button.attrs(setButtonLinkProps)<
  ButtonProps & { hasIcon: boolean }
>`
  ${switchProp('colorTheme', buttonThemes)}
  ${switchProp('size', buttonSizes)}

  -webkit-tap-highlight-color: transparent;
  background-color: var(--button-background-color);
  border: 1px solid var(--button-border-color);
  border-radius: var(--button-border-radius);
  color: var(--button-text-color);
  cursor: pointer;
  display: inline-flex;
  font-family: var(--font-family-poppins);
  font-size: var(--button-font-size);
  font-weight: 400;
  justify-content: center;
  min-height: var(--button-height);
  outline: none;
  padding: 8px 24px;
  place-items: center;
  position: relative;
  text-transform: lowercase;
  transition: var(--transition-default);
  transition-property: background-color, border-color, color, opacity;

  &:focus,
  &:hover {
    background-color: var(--button-active-background-color);
    border-color: var(--button-active-border-color);
    color: var(--button-active-text-color);
  }

  ${ifProp('stretch', 'width: 100%;')}
  ${ifProp('hasIcon', 'padding-right: 22px;')}
  ${ifProp(
    'disabled',
    `
    background-color: var(--button-disabled-background-color);
    border-color: var(--button-disabled-border-color);
    color: var(--button-disabled-text-color);
    cursor: default;
    pointer-events: none;
  `
  )}
`;

/**
 * Export `Button` component.
 */

export const Button = forwardRef<any, ButtonProps>(
  (props: ButtonProps, ref: any) => {
    const {
      children,
      colorTheme = 'green',
      disabled,
      icon,
      isLoading,
      size = 'medium',
      ...rest
    } = props;

    return (
      <Wrapper
        colorTheme={colorTheme}
        disabled={isLoading || disabled}
        hasIcon={!!icon}
        isLoading={isLoading}
        ref={ref}
        size={size}
        {...rest}
      >
        {isLoading && <StyledLoading />}

        {children}

        {icon && (
          <Icon
            icon={icon}
            size={size === 'small' ? '16px' : '24px'}
          />
        )}
      </Wrapper>
    );
  }
);

/**
 * `Button` display name.
 */

Button.displayName = 'Button';
