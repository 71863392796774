/**
 * Module dependencies.
 */

import {
  Context,
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';

import { NavbarTheme } from 'src/types/app';
import { Scroll, useLocomotiveScroll } from 'react-locomotive-scroll';

/**
 * `ContextProps` type.
 */

type ContextProps = {
  scrollPosition: number;
  setTheme: Dispatch<SetStateAction<NavbarTheme>>;
  theme: NavbarTheme;
};

/**
 * `ProviderProps` type.
 */

type ProviderProps = {
  children: ReactNode;
};

/**
 * `NavbarThemeContext` context.
 */

const NavbarThemeContext: Context<ContextProps> = createContext(
  {} as ContextProps
);

/**
 * Export `NavbarThemeProvider` provider.
 */

export const NavbarThemeProvider = ({ children }: ProviderProps) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [theme, setTheme] = useState<NavbarTheme>('dark');
  const locomotiveScroll = useLocomotiveScroll();

  useEffect(() => {
    if (locomotiveScroll?.scroll) {
      try {
        locomotiveScroll?.scroll.on('scroll', ({ scroll }: Scroll) => {
          setScrollPosition(scroll.y);
        });
      } catch (error) {
        setScrollPosition(0);
      }
    }
  }, [locomotiveScroll?.scroll]);

  return (
    <NavbarThemeContext.Provider
      value={{
        scrollPosition,
        setTheme,
        theme
      }}
    >
      {children}
    </NavbarThemeContext.Provider>
  );
};

/**
 * Export `useNavbarTheme` hook.
 */

export const useNavbarTheme = () => useContext(NavbarThemeContext);
