/**
 * Module dependencies.
 */

import { regexes } from 'src/core/constants/regexes';
import { resolve } from 'url';

/**
 * Export `absoluteUrlResolve` util.
 */

export function absoluteUrlResolve(path: string): string {
  return resolve(process.env.NEXT_PUBLIC_BASE_URL as string, path);
}

/**
 * Export `getFileUriExtension` util.
 */

export function getFileUriExtension(
  uri: string | undefined
): string | undefined {
  const dot = '.';

  if (typeof uri !== 'string' || !uri.includes(dot)) {
    return undefined;
  }

  return uri.substring(uri.lastIndexOf(dot) + 1);
}

/**
 * Export `isExternalUrl`.
 */

export function isExternalUrl(url: string | undefined): boolean {
  if (!url) {
    return false;
  }

  return (
    regexes.url.test(url) ||
    regexes.emailLink.test(url) ||
    regexes.telLink.test(url)
  );
}
