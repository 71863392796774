/**
 * Module dependencies.
 */

import { CheckboxField } from 'src/components/core/forms/checkbox/field';
import { Control } from 'react-hook-form';
import { InputProps } from 'src/components/core/forms/input';
import { Link } from 'src/components/core/links/link';
import { Trans, useTranslation } from 'next-i18next';
import { requiredRule } from 'src/core/forms/rules';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  control: Control<any>;
  size?: 'medium' | 'small';
} & InputProps;

/**
 * `AgreementsWrapper` styled component.
 */

const AgreementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

/**
 * `privacyLink` component.
 */

const privacyLink = [
  <Link
    href={process.env.NEXT_PUBLIC_PRIVATE_POLICY_URL}
    key={'privacyLink'}
    style={{ fontWeight: 'bold' }}
    target={'_blank'}
  />
];

/**
 * Export `NewsletterAndTermsFields` .
 */

export function NewsletterAndTermsFields(props: Props) {
  const { control, size, ...inputProps } = props;
  const { t } = useTranslation('common');

  return (
    <AgreementsWrapper>
      <CheckboxField
        {...inputProps}
        control={control as any}
        label={(
          <Trans
            components={privacyLink}
            i18nKey={'footer.newsletter.accept'}
            transSupportBasicHtmlNodes
          />
        )}
        name={'privacyPolicy'}
        rules={requiredRule(t)}
        size={size}
      />

      <CheckboxField
        {...inputProps}
        control={control as any}
        label={t('form.labels.newsletter')}
        name={'subscribeToNewsletter'}
        size={size}
      />
    </AgreementsWrapper>
  );
}
