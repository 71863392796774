/**
 * Module dependencies.
 */

import { ReactNode, createElement } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  className?: string;
  element?: string;
};

/**
 * Export `RawHtml` component.
 */

export const RawHtml = ({ children, element, ...props }: Props) => {
  return createElement(element ?? 'span', {
    ...props,
    dangerouslySetInnerHTML: { __html: children } // eslint-disable-line id-match
  });
};
