/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Container } from 'src/components/core/layout/container';
import { Image } from 'src/components/core/image';
import { Link } from 'src/components/core/links/link';
import { NewsletterForm } from 'src/components/forms/newsletter';
import {
  NewsletterFormData,
  subscribeNewsletter
} from 'src/requests/newsletter';
import { RawHtml } from 'src/components/core/raw-html';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { media } from 'src/styles/utils/media';
import { socialNetworks } from 'src/core/constants/social-networks';
import { toast } from 'src/providers/toast';
import { useBreakpoint } from '@untile/react-components/dist/hooks';
import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import iconButton from 'src/assets/svg/button.svg';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.footer`
  --footer-newsletter-padding-top: 64px;
  --footer-title-margin-bottom: clamp(56px, 10vw, 120px);

  background: var(--gradient-primary);
  color: var(--color-white);
  overflow: hidden;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-areas: 'title' 'contacts' 'newsletter' 'social' 'list' 'copyright';
  padding: clamp(48px, 10vw, 120px) 0 48px;

  ${media.min.md`
    grid-row-gap: 0;
    grid-template-areas:
      'title title title .'
      '. . . .'
      'newsletter newsletter . contacts'
      '. . . .'
      'social social social social'
      'list list list list'
      'copyright copyright copyright copyright';

    grid-template-columns: repeat(4, 1fr);
    grid-template-rows:
      auto
      var(--footer-title-margin-bottom)
      auto
      var(--footer-title-margin-bottom)
      auto;
  `}

  ${media.min.lg`
    grid-template-areas:
      'title title .'
      '. . .'
      'newsletter newsletter contacts'
      '. . .'
      'social copyright list';
    grid-template-columns: repeat(3, 1fr);
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({
  variant: 'display1'
})`
  display: block;
  grid-area: title;
  position: relative;
  z-index: 1;

  ${media.max.md`
    margin-bottom: 48px;
  `}
`;

/**
 * `NewsLetter` styled component.
 */

const NewsLetter = styled.div`
  display: grid;
  grid-area: newsletter;
  grid-row-gap: clamp(40px, 8vw, 80px);
  max-width: 500px;
  padding-top: 24px;
  position: relative;
  z-index: 1;

  ${media.min.lg`
    padding-top: var(--footer-newsletter-padding-top);
  `}
`;

/**
 * `Copyright` styled component.
 */

const Copyright = styled(Text)`
  align-self: center;
  display: flex;
  grid-area: copyright;
  position: relative;
  z-index: 1;

  ${media.min.lg`
    justify-self: center;
  `}
`;

/**
 * `SocialNetworks` styled component.
 */

const SocialNetworks = styled.div`
  align-self: center;
  display: grid;
  grid-area: social;
  grid-auto-flow: column;
  grid-gap: 8px;
  max-width: 168px;
  position: relative;
  z-index: 1;

  ${Link} {
    height: fit-content;

    > span {
      display: block;
    }
  }
`;

/**
 * `LinksList` styled component.
 */

const LinksList = styled.ul`
  align-self: center;
  grid-area: list;
  position: relative;
  text-align: left;
  z-index: 1;

  ${media.min.lg`
    display: flex;
    flex-direction: row;
    justify-self: flex-end;
    white-space: nowrap;

    li + li::before {
      content: '|';
      padding: 0 1ch;
    }
  `}
`;

/**
 * `Contacts` styled component.
 */

const Contacts = styled.div`
  align-items: flex-end;
  grid-area: contacts;
  justify-content: flex-end;
  position: relative;

  ${media.min.lg`
    text-align: right;
  `}
`;

/**
 * `ContactsContent` styled component.
 */

const ContactsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  z-index: 1;
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  bottom: -15%;
  position: absolute;
  right: 15%;
  transform: translate(60%, 5%) scale(0.65);

  ${media.min.md`
    transform: translate(5%, 5%) scale(0.8);
  `}

  ${media.min.lg`
    transform: scale(1);
  `}
`;

/**
 * Links URL's.
 */

const linksUrls = {
  cookies: process.env.NEXT_PUBLIC_COOKIES_POLICY_URL,
  privacy: process.env.NEXT_PUBLIC_PRIVATE_POLICY_URL,
  termsAndConditions: process.env.NEXT_PUBLIC_TERMS_AND_CONDITIONS_URL
} as const;

/**
 * Export `Footer` component.
 */

export function Footer() {
  const { t } = useTranslation('common');
  const links = Object.entries(t('footer.links', { returnObjects: true }));
  const isDesktop = useBreakpoint('xs');
  const year = new Date().getFullYear();
  const handleSubscribe = useCallback(
    async (data: NewsletterFormData, reset: () => void) => {
      try {
        await subscribeNewsletter(data);

        reset();
        toast.success(t('footer.newsletter.network.success'));
      } catch (error) {
        toast.error(t('footer.newsletter.network.error'));
      }
    },
    [t]
  );

  return (
    <Wrapper>
      <Container>
        <Grid>
          <Title
            data-scroll={'fade-in-up'}
            data-scroll-offset={'20%'}
          >
            <RawHtml>{t('footer.lead')}</RawHtml>
          </Title>

          <NewsLetter>
            <Text
              data-scroll={'fade-in-up'}
              data-scroll-offset={'20%'}
              variant={'subtitle2'}
            >
              <RawHtml>{t('footer.newsletter.title')}</RawHtml>
            </Text>

            <NewsletterForm
              data-scroll={'fade-in-up'}
              data-scroll-offset={'20%'}
              onSubmit={handleSubscribe}
            />
          </NewsLetter>

          <Contacts>
            <StyledImage
              alt={'Map'}
              height={651}
              src={'/images/footer-map.png'}
              width={630}
            />

            <ContactsContent>
              <address>
                <div
                  data-scroll={'fade-in-up'}
                  data-scroll-id={'footer-phone-contact'}
                  data-scroll-offset={'20%'}
                >
                  <Link href={`tel:${process.env.NEXT_PUBLIC_OUSIA_PHONE}`}>
                    <Text variant={'display3'}>
                      {process.env.NEXT_PUBLIC_OUSIA_PHONE}
                    </Text>
                  </Link>
                </div>

                <div
                  data-scroll={'fade-in-up'}
                  data-scroll-offset={'15%'}
                  style={{ marginBottom: 24 }}
                >
                  <Text
                    block
                    variant={'subtitle2'}
                  >
                    <RawHtml>{t('footer.contacts.address')}</RawHtml>
                  </Text>

                  <Link href={`mailto:${process.env.NEXT_PUBLIC_OUSIA_EMAIL}`}>
                    <Text variant={'subtitle2'}>
                      {process.env.NEXT_PUBLIC_OUSIA_EMAIL}
                    </Text>
                  </Link>
                </div>
              </address>

              <div
                data-scroll={'fade-in-up'}
                data-scroll-offset={'15%'}
              >
                <Button
                  aria-label={'Google maps link'}
                  colorTheme={'whiteOutlined'}
                  href={process.env.NEXT_PUBLIC_MAP_URL}
                  icon={iconButton}
                >
                  {t(
                    isDesktop
                      ? 'footer.contacts.openMap'
                      : 'footer.contacts.openMapMobile'
                  )}
                </Button>
              </div>
            </ContactsContent>
          </Contacts>

          <SocialNetworks data-scroll={'fade-in-up'}>
            {socialNetworks.map(
              (network, index) =>
                network?.url && (
                  <Link
                    aria-label={network?.label}
                    href={network?.url}
                    key={index}
                  >
                    <Svg
                      icon={network.icon}
                      size={'48px'}
                    />
                  </Link>
                )
            )}
          </SocialNetworks>

          <Copyright data-scroll={'fade-in-up'}>
            {t('footer.copyright', { year })}
          </Copyright>

          <LinksList data-scroll={'fade-in-up'}>
            {links.map(([key, label]) => (
              <li key={key}>
                <Link
                  href={linksUrls?.[key as keyof typeof linksUrls]}
                  target={'_blank'}
                >
                  {label as string}
                </Link>
              </li>
            ))}
          </LinksList>
        </Grid>
      </Container>
    </Wrapper>
  );
}
