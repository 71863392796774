/**
 * Module dependencies.
 */

import { ContactButtons } from './contact-buttons';
import { Locale } from 'i18n-routes';
import { Menu } from './menu';
import { NavbarTheme } from 'src/types/app';
import { RoundButton } from 'src/components/core/buttons/round-button';
import { RouterLink } from 'src/components/core/links/router-link';
import { Scroll, useLocomotiveScroll } from 'react-locomotive-scroll';
import { Search } from './search';
import { Svg } from 'src/components/core/svg';
import { TransitionWrapper } from './transition-wrapper';
import { getRoute } from 'src/core/utils/routes';
import { ifProp, switchProp } from 'styled-tools';
import { media } from 'src/styles/utils/media';
import { transparentize } from 'src/styles/utils/colors';
import { useBodyScroll, useBreakpoint } from '@untile/react-components';
import { useNavbarTheme } from 'src/providers/navbar-theme';
import { usePreviousState } from 'src/hooks/use-previous-state';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import svgClose from 'src/assets/svg/close.svg';
import svgLogo from 'src/assets/svg/logo.svg';
import svgMenu from 'src/assets/svg/menu.svg';
import svgSearch from 'src/assets/svg/search.svg';

/**
 * `Props` type.
 */

type Props = {
  hasNavigation?: boolean;
};

/**
 * `navbarBackground` constant.
 */

const navbarBackground = {
  dark: transparentize('primary', 0.88),
  light: transparentize('white', 0.88)
};

/**
 * `Nav` styled component.
 */

const Nav = styled.nav<{
  hasNavigation: boolean;
  showBackdrop: boolean;
  theme: NavbarTheme;
}>`
  align-items: center;
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: max-content 1fr;
  height: var(--navbar-height);
  left: 0;
  padding: 20px var(--navbar-gutter) 12px;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(0, 0, 0);
  z-index: var(--z-index-navbar);

  ::before {
    background-color: ${switchProp('theme', navbarBackground, 'transparent')};
    content: '';
    inset: 0;
    opacity: ${ifProp('showBackdrop', 1, 0)};
    position: absolute;
    transition: var(--transition-slow), var(--transition-default);
    transition-property: opacity, background-color;
  }

  ${ifProp('hasNavigation', 'pointer-events: none;')}

  ${media.min.sm`
    padding: 16px var(--navbar-gutter);
  `}
`;

/**
 * `Actions` styled component.
 */

const Actions = styled(TransitionWrapper)`
  column-gap: 32px;
  display: flex;
  justify-self: end;
`;

/**
 * `LogoLink` styled component.
 */

const LogoLink = styled(RouterLink)<{ isDarkTheme: boolean }>`
  align-self: flex-end;
  position: relative;
  z-index: var(--z-index-navbar-logo);

  ${ifProp(
    'isDarkTheme',
    `
    path {
      fill: var(--color-white) !important;
    }
  `
  )}
`;

/**
 * `SearchButton` styled component.
 */

const SearchButton = styled(RoundButton)`
  --button-active-text-color: var(--color-sage700);
  --button-background-color: transparent;
  --button-active-background-color: transparent;
  --button-disabled-background-color: transparent;

  border: none !important;
`;

/**
 * Export `Navbar` component.
 */

export function Navbar({ hasNavigation }: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const { setTheme, theme } = useNavbarTheme();
  const router = useRouter();
  const locomotiveScroll = useLocomotiveScroll();
  const isDarkTheme = theme === 'dark';
  const isDesktop = useBreakpoint('lg');
  const isMobile = useBreakpoint('sm', 'max');
  const previousTheme = usePreviousState<NavbarTheme>(theme);
  const handleOnClose = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);

    if (previousTheme) {
      setTheme(previousTheme);
    }
  }, [isMenuOpen, previousTheme, setTheme]);

  useBodyScroll({ off: isMenuOpen || isSearchOpen });

  useEffect(() => {
    if (locomotiveScroll?.scroll) {
      try {
        locomotiveScroll.scroll.on('scroll', ({ scroll }: Scroll) => {
          setIsScrolled(scroll?.y > (isMobile ? 30 : 70));
        });
      } catch (error) {
        setIsScrolled(false);
      }
    }
  }, [isMobile, locomotiveScroll.scroll]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }

      if (isSearchOpen) {
        setIsSearchOpen(false);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [isMenuOpen, isSearchOpen, router.events]);

  return (
    <>
      <Nav
        hasNavigation={isSearchOpen || !hasNavigation}
        id={'navbar'}
        showBackdrop={isScrolled && !(isSearchOpen || isMenuOpen)}
        theme={!isScrolled && !(isSearchOpen || isMenuOpen) ? undefined : theme}
      >
        <LogoLink
          aria-label={'Ousia Clinic'}
          href={getRoute('home', router?.locale as Locale)}
          isDarkTheme={isDarkTheme}
        >
          <Svg
            icon={svgLogo}
            size={isMobile ? '120px' : '214px'}
          />
        </LogoLink>

        {hasNavigation && (
          <Actions isOpen={!isSearchOpen}>
            {isDesktop && <ContactButtons />}

            <SearchButton
              aria-label={'Search'}
              colorTheme={isDarkTheme ? 'whiteOutlined' : 'greenOutlined'}
              icon={svgSearch}
              onClick={() => {
                setIsMenuOpen(false);
                setIsSearchOpen(true);
              }}
            />

            <RoundButton
              aria-label={'Menu'}
              colorTheme={isDarkTheme ? 'white' : 'green'}
              icon={isMenuOpen ? svgClose : svgMenu}
              onClick={handleOnClose}
            />
          </Actions>
        )}
      </Nav>

      {hasNavigation && (
        <>
          <Menu isOpen={isMenuOpen} />

          <Search
            isOpen={isSearchOpen}
            onClose={() => setIsSearchOpen(false)}
          />
        </>
      )}
    </>
  );
}
